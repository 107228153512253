import {
  createStore, applyMiddleware, combineReducers, compose,
} from "redux";
import axios from "axios";
import { handleRequests } from "@redux-requests/core";
import { createDriver } from "@redux-requests/axios";
import { languageLSKey } from "util/configs/general";
import get from "lodash/get";
import settingsReducer from "./slices/settingsSlice";
import viewProcessReducer from "./slices/viewProcessSlice";
import plannerReducer from "./slices/plannerSlice";
import processesReducer from "./slices/processesSlice";
import rpaQualReducer from "./slices/rpaQualSlice";
import stepperReducer from "./slices/stepperSlice";
import rootReducer from "./rootReducer";
import workqueueitemReducer from "./slices/workqueueitemSlice";
import adminFilter from "./slices/adminFilterSlice";
import keycloakConf from "../conf/keycloakConf";

export const configureStore = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_DOMAIN,
  });
  instance.defaults.withCredentials = true;
  instance.interceptors.request.use((config) => {
    config.headers.common["X-Requested-With"] = "XMLHttpRequest";
    config.headers.common["Accept-Language"] = localStorage.getItem(languageLSKey)
      ? localStorage.getItem(languageLSKey)
      : "fr";
    if (keycloakConf?.token) {
      config.headers.Authorization = `Bearer ${keycloakConf.token}`;
    }
    const storedTimezone = localStorage.getItem("sr-tz");
    if (
      storedTimezone
      && Intl.supportedValuesOf("timeZone").includes(storedTimezone)
    ) {
      config.headers["sr-client-timezone"] = storedTimezone;
    } else {
      const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      config.headers["sr-client-timezone"] = detectedTimezone;
      localStorage.setItem("sr-tz", detectedTimezone);
    }
    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (get(error, "response.status") === 402) {
        window.location.href = "/license";
      } else if (get(error, "response.status") === 401) {
        // const currectUrl = window.location.href;
        // window.location.href = `${
        //   process.env.REACT_APP_GATEWAY
        // }/login/sso?redirect_url=${encodeURI(currectUrl)}`;
      }
      return Promise.reject(error);
    },
  );

  const { requestsReducer, requestsMiddleware } = handleRequests({
    driver: createDriver(instance),
  });

  const reducers = combineReducers({
    requests: requestsReducer,
    settings: settingsReducer,
    viewProcess: viewProcessReducer,
    planner: plannerReducer,
    processes: processesReducer,
    stepper: stepperReducer,
    rpaQual: rpaQualReducer,
    workqueueitem: workqueueitemReducer,
    adminFilter,
    ...rootReducer,
  });

  const composeEnhancers = (typeof window !== "undefined"
      && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
    || compose;

  const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(...requestsMiddleware)),
  );

  return store;
};

export default configureStore;
