import React, { useState, useEffect, useMemo } from "react";
import Grid from "@material-ui/core/Grid"
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import get from "lodash/get";
import { toast } from "react-toastify";
import { useQuery } from "@redux-requests/react";
import {
  fetchUser,
  createUser,
  modifyUser,
  fetchUserProcesses,
  fetchCivilities,
  fetchFleetsForFilter,
  fetchAllTagsOfUser,
  fetchFleetTags,
  fetchProcessesForAdministration,
  fetchPermissionsRoles,
  fetchFleetProcesses,
} from "redux/actions/services/index";
import {
  isFleetAdministrator,
  REGEX,
} from "util";
import { FETCH_FLEET_TAGS } from "redux/constants";
import { USERS_BASE_URL } from "util/helpers/route.helper";
import CustomSwitch from "../../../Services/components/CustomSwitch";
import CustomButton from "components/CustomButton";
import CircularLoader from "components/Loaders/CircularLoader";
import DialogWithTabs from "components/FormComponents/DialogWithTabs";
import CustomAutoComplete, { CustomTextField } from "components/FormFields/CustomAutoComplete";
import CustomSelectField from "components/FormFields/CustomSelectField";
import ConfirmMessage from "components/ConfirmMessage";
import ProcessAndTagSelector from "pages/Administration/ProcessAndTagSelector";
import useStyles from "../../FleetManagement/FleetForm/style";

const tabs = [
  {
    id: "user-fleetInfo-tab",
    label: "fleet.formSection.fleetInfo",
  },
  {
    id: "user-processesTags-tab",
    label: "fleet.formSection.processesTags",
  },
];

const AddUser = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { idUser, mode = "add" } = useParams();

  const civilities = useSelector(
    ({ requests }) => requests.queries.FETCH_USERS_CIVILITIES?.data,
  );

  const fleets = useSelector(
    ({ requests }) => requests.queries.FETCH_ALL_FLEETS_FOR_FILTER?.data,
  );

  const jobs = useSelector(
    ({ requests }) => requests.queries.FETCH_USERS_JOBS?.data,
  );

  const userProcesses = useSelector(
    ({ requests }) => requests.queries.FETCH_USER_PROCESSES?.data,
  );
  const fleetTags = useQuery({ type: FETCH_FLEET_TAGS })?.data;
  const userTags = useSelector(
    ({ requests }) => requests.queries.FETCH_ALL_TAGS_OF_USER?.data,
  );

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const [fleet, setFleet] = useState({});
  const [assignedTags, setAssignedTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openMsgCancel, setOpenMsgCancel] = useState(false);
  const [availableProcesses, setAvailableProcesses] = useState([]);
  const [assignedProcesses, setAssignedProcesses] = useState([]);
  const [fleetChanged, setFleetChanged] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [userDataLoading, setUserDataLoading] = useState(false);
  const isCurrentUserAdmin = useMemo(() => isFleetAdministrator(currentUser), [currentUser],);
  const disableFields = !(mode === "add" || mode === "edit");
  const enabledTabs = tabs.filter((item) => !item.disabledTab);
  const [roles, setRoles] = useState([]);
  const {
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    clearErrors,
    formState: { isDirty },
    register,
      watch
  } = useForm({
    mode: "onChange",
    defaultValues: {
      civility: "",
      fleet: {},
      lastName: "",
      firstName: "",
      email: "",
      phoneNumber: "",
      requireOtp: false,
      isAdmin: false,
      inheritGroupPermissions: true,
    },
  });

  useEffect(() => {
    if (mode !== "add") {
      setUserDataLoading(true);
      if (fleets) {
        dispatch(fetchUser(idUser)).then((res) => {
          setValue("civility", res?.data?.civility?.code);
          setValue("lastName", res?.data?.lastName);
          setValue("firstName", res?.data?.firstName);
          setValue("email", res?.data?.email);
          setValue("fleet", fleets?.find(({ id }) => id === res?.data?.fleet?.id));
          setValue("initialfleetId", fleets?.find(({ id }) => id === res?.data?.fleet?.id)?.id);
          setValue("phoneNumber", res?.data?.phoneNumber);
          setValue("isAdmin", res?.data?.isAdmin);
          setValue("requireOtp", res?.data?.requireOtp);
          setValue("inheritGroupPermissions", res?.data?.inheritGroupPermissions);
          setValue("inheritGroupAutomations", res?.data?.inheritGroupAutomations);
          setValue("role", res.data?.role);
          setFleet(res?.data?.fleet);
          setUserDataLoading(false);
        });
      }
    } else handleDefaulRole()
  }, [mode, idUser, fleets]);

  useEffect(() => {
    dispatch(fetchCivilities()).then((response) => {
      dispatch(fetchFleetsForFilter());
      if (mode === "add") {
        setValue("civility", response?.data[0]?.code);
      }
    });
  }, [mode, idUser]);

  useEffect(() => {
    if (mode === "add" && !watch("inheritGroupAutomations")) {
      if (fleet?.id)
      {
        dispatch(fetchProcessesForAdministration({ tagsIds: [], fleetIds: [fleet.id] })).then(
            (res) => {
              setAvailableProcesses(res?.data ?? [])
              setAssignedProcesses([])
            },
        );
      }
    } else if (mode === "view") {
      if (idUser) {
        distributeProcesses();
      }
    } else if (mode === "edit" && !watch("inheritGroupAutomations")) {
      if (idUser) {
        distributeProcesses();
      }
    }
  }, [fleet, fleetChanged, idUser, activeStep]);

  useEffect(() => {
      if (watch("inheritGroupAutomations") && watch("fleet")?.id) {
        dispatch(fetchFleetProcesses(watch("fleet")?.id)).then((res) => {
          setAssignedProcesses(res?.data?.list ?? [])
          setAvailableProcesses([])
        })
        dispatch(fetchFleetTags(watch("fleet")?.id)).then(() => {
          setAssignedTags(fleetTags ?? [])
          setAvailableTags([])
        })
      }
  }, [watch("fleet"), watch("inheritGroupAutomations"), activeStep]);
  const distributeProcesses = () => {
    if (fleet?.id) {
      setAssignedProcesses(userProcesses?.list);
      dispatch(fetchProcessesForAdministration({ tagsIds: [], fleetIds: [fleet.id] })).then(
          (res) => {
            const userPorcessIds = userProcesses?.list?.map(({ id }) => id);
            const available = res?.data?.filter(({ id }) => !userPorcessIds.includes(id)) ?? [];
            setAvailableProcesses(available);
          },
      );
    }
  }

  useEffect(() => {
    // TODO update this function to use userTags
    if (mode === "add" && fleet?.id && !watch("inheritGroupAutomations")) {
      dispatch(fetchFleetTags(fleet.id)).then((result) => {
        setAvailableTags(result?.data?.filter((element) => !assignedTags?.map((tag) => tag.id)?.includes(element.id)));
        setAssignedTags((prevState) => prevState.filter((element) => result?.data?.map((tag) => tag.id)?.includes(element.id)));
      });
    } else if (mode === "view" && idUser && fleet?.id) {
      dispatch(fetchFleetTags(fleet.id)).then((result) => {
        setAvailableTags(result?.data?.filter((element) => !userTags?.map((tag) => tag.id)?.includes(element.id)));
      });
      setAssignedTags(userTags ?? []);
    } else if (mode === "edit" && idUser && fleet?.id && !watch("inheritGroupAutomations")) {
      setAssignedTags(userTags ?? []);
      dispatch(fetchFleetTags(fleet.id)).then((result) => {
        setAvailableTags(result?.data?.filter((element) => !userTags?.map((tag) => tag.id)?.includes(element.id)));
      });
    }
  }, [idUser, activeStep, fleet])

  useEffect(() => {
    if (idUser) {
      dispatch(fetchUserProcesses(idUser, false));
      dispatch(fetchAllTagsOfUser(idUser));
    }
  }, [idUser, dispatch]);

  useEffect(() => {
    if (idUser) setAssignedProcesses(userProcesses?.list ?? []);
  }, [idUser, userProcesses]);

  useEffect(() => {
    if (idUser) setAssignedTags(userTags?.list ?? []);
  }, [idUser, userTags])

  useEffect(() => {
    if (mode === "add" && fleets && currentUser && currentUser?.fleet) {
      const tempFleet = fleets?.find(({ id }) => id === currentUser?.fleet?.id);
      setFleet(tempFleet);
      setValue("fleet", tempFleet);
    }
  }, [currentUser, fleets]);

  const handleCancel = () => {
    if (mode === "view" || !isDirty) {
      redirectToList();
      return;
    }
    setOpenMsgCancel(true);
  };
  const redirectToList = () => history.push(USERS_BASE_URL);

  const handleBack = () => {
    if (activeStep <= 0) handleCancel();
    setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? 0 : prevActiveStep - 1));
  };

  const handleSaveClick = async () => {
    const result = await trigger();
    if (result) {
      if (activeStep === enabledTabs.length - 1) {
        setOpenMsgConfirm(true);
      }
      if (activeStep < enabledTabs.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
    setIsLoading(false);
  };

  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    redirectToList();
  };

  const handleRejectCancelForm = () => setOpenMsgCancel(false);

  const confirmSave = () => {
    handleSubmit(onSubmit)();
  };
  const onSubmit = (data) => {
    const jobTitle = jobs?.find((j) => j.code === "smartroby_user");
    const isAdmin = (currentUser?.id === idUser) ? isCurrentUserAdmin : data.isAdmin;
    const dataToSubmit = {
      ...data,
      civility: civilities.find((civ) => civ.code === data.civility),
      jobTitle,
      permissions: data?.role?.permissions,
      role: data?.role,
      tagsIds: assignedTags.map((element) => element.id),
      processIds: assignedProcesses.map((element) => element.id),
      isAdmin
    };
    setIsLoading(true);
    if (mode === "add") {
      dispatch(
        createUser(dataToSubmit, (res) => {
          if (res?.status === 200) {
            setOpenMsgConfirm(false);
            redirectToList();
            toast.success(t("user.formControl.saveSuccess"))
          }
          setIsLoading(false);
        }),
      )
        .then((res) => {
          if (res.response.status !== "OK") {
            setOpenMsgConfirm(false);
            toast.error(t(res.response?.data?.detail))
          }
        })
        .catch(() => {});
    } else if (mode === "edit") {
      dispatch(
        modifyUser(idUser, dataToSubmit, (res) => {
          if (res?.status === 200) {
            setOpenMsgConfirm(false);
            redirectToList();
            toast.success(t("user.formControl.updateSuccess"))
          }
          setIsLoading(false);
        }),
      )
        .then((res) => {
          if (res.response.status !== "OK") {
            setOpenMsgConfirm(false);
            toast.error(t(res.response.data.title))
          }
        })
        .catch(() => {});
    }
  };

  const handleChange = async (_, newValue) => {
    const result = await trigger();
    if (result) {
      setActiveStep(newValue);
    }
  };

  const onSuccess = (res) => {
    setRoles(res?.data?.content);
  };

  useEffect(() => {
    dispatch(fetchPermissionsRoles({ size: 2000 }, onSuccess, () => "", t));
  }, []);

  const handleDefaulRole = () => setValue("role", roles?.find(({ id }) => id === watch("fleet")?.roleId))

  useEffect(() => {
    if (watch("inheritGroupPermissions")) handleDefaulRole();
  }, [watch("inheritGroupPermissions"), watch("fleet")]);

  const switchFields = [
    {
      label: "user.add.inherit.group.permissions",
      fieldName: "inheritGroupPermissions",
      disabled: disableFields || !isCurrentUserAdmin,
      isAuthorized: isCurrentUserAdmin,
    },
    {
      label: "user.add.fleet.admin",
      fieldName: "isAdmin",
      disabled: disableFields || currentUser?.id === idUser,
      isAuthorized: isCurrentUserAdmin,
    },
    {
      label: "user.add.mfa",
      fieldName: "requireOtp",
      disabled: disableFields,
      isAuthorized: true,
    },
    {
      label: "Inherit group automations",
      fieldName: "inheritGroupAutomations",
      disabled: disableFields,
      isAuthorized: true,
    },
  ];
  const enableFleetEdit = () => {
    const isAddMode = mode === "add";
    const isEditMode = mode === "edit";
    const isInstanceOwnerAdmin = currentUser?.fleet?.instanceOwner;
    const isCurrentUserFleet = currentUser?.fleet?.id === watch("initialfleetId");
    return isFleetAdministrator(currentUser)
        && ((isAddMode && isInstanceOwnerAdmin) || (isEditMode && (isInstanceOwnerAdmin || isCurrentUserFleet)));
  }
  const tabsContents = [
    {
      content: userDataLoading ? (
        <CircularLoader height="100%" />
      ) : (
        <Grid container direction="column" alignItems="center" spacing={3}>
          <Grid container item justify="space-between">
            <Grid item xs={5}>
              <Controller
                control={control}
                name="fleet"
                rules={{
                  required: {
                    value: true,
                    message: t("user.management.formControl.required"),
                  },
                }}
                render={(field) => (
                  <CustomAutoComplete
                    {...field}
                    key={`selected-fleet-${getValues("fleet")?.id}`}
                    value={getValues("fleet")}
                    options={fleets || []}
                    optionLabel="companyName"
                    label={`${t("user.management.formLabel.fleet")} *`}
                    error={!!errors.fleet?.message}
                    onChange={(value) => {
                      clearErrors("fleet");
                      setValue("fleet", value);
                      setFleet(value);
                      setFleetChanged(true);
                    }}
                    disabled={!enableFleetEdit()}
                  />
                )}
              />
              <p className={classes.validation_error}>
                {errors.fleet?.message}
              </p>
            </Grid>
            <Grid item xs={5}>
              <CustomSelectField
                options={civilities}
                optionLabel="label"
                optionValue="code"
                {...register("civility", {
                  required: {
                    value: true,
                    message: t("user.management.formControl.required"),
                  },
                })}
                fullWidth
                onChange={(e) => {
                  clearErrors("civility");
                  setValue("civility", e.target.value);
                }}
                value={getValues("civility")}
                label={t("user.add.civility")}
                disabled={disableFields}
                isCustom
              />
              <p className={classes.validation_error}>
                {errors.subscription?.message}
              </p>
            </Grid>
          </Grid>
          <Grid container item justify="space-between">
            <Grid item xs={5}>
              <CustomTextField
                {...register("lastName", {
                  required: {
                    value: true,
                    message: t("user.management.formControl.required"),
                  },
                })}
                id="lastName"
                label={`${t("user.add.lastName")} *`}
                fullWidth
                InputLabelProps={{
                  shrink: !!getValues("lastName"),
                }}
                error={!!errors.lastName?.message}
                onChange={(event) => {
                  clearErrors("lastName");
                  setValue("lastName", event.target.value);
                }}
                valued={getValues("lastName")}
                disabled={disableFields}
                helperText={errors.lastName?.message}
              />
            </Grid>
            <Grid item xs={5}>
              <CustomTextField
                id="firstName"
                {...register("firstName", {
                  required: {
                    value: true,
                    message: t("user.management.formControl.required"),
                  },
                })}
                label={`${t("user.add.firstName")} *`}
                fullWidth
                InputLabelProps={{
                  shrink: !!getValues("firstName"),
                }}
                error={!!errors.firstName?.message}
                onChange={(event) => {
                  clearErrors("firstName");
                  setValue("firstName", event.target.value);
                }}
                valued={getValues("firstName")}
                disabled={disableFields}
                helperText={errors.firstName?.message}
              />
            </Grid>
          </Grid>
          <Grid container item justify="space-between">
            <Grid item xs={5}>
              <CustomTextField
                id="email"
                {...register("email", {
                  required: {
                    value: true,
                    message: t("user.management.formControl.required"),
                  },
                  pattern: {
                    value: REGEX.EMAIL,
                    message: t("user.management.formControl.email"),
                  },
                })}
                label={`${t("user.add.email")} *`}
                fullWidth
                InputLabelProps={{
                  shrink: !!getValues("email"),
                }}
                error={!!errors.email?.message}
                onChange={(event) => {
                  clearErrors("email");
                  setValue("email", event.target.value);
                }}
                valued={getValues("email")}
                disabled={mode !== "add"}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={5}>
              <CustomTextField
                id="phoneNumber"
                {...register("phoneNumber", {
                  pattern: {
                    value: REGEX.TEL,
                    message: t("user.management.formControl.phone"),
                  },
                })}
                label={t("fleet.management.formLabel.phoneNumber")}
                fullWidth
                error={!!errors.phoneNumber?.message}
                onChange={(event) => {
                  clearErrors("phoneNumber");
                  setValue("phoneNumber", event.target.value);
                }}
                InputLabelProps={{
                  shrink: !!getValues("phoneNumber"),
                }}
                valued={getValues("phoneNumber")}
                disabled={disableFields}
                helperText={errors.phoneNumber?.message}
              />
            </Grid>
          </Grid>
          <Grid container item justify="space-between">
            <Grid item xs={5}>
              <FormControl fullWidth>
                <Controller
                      control={control}
                      key={`role-inheritGroupPermissions-${watch("inheritGroupPermissions")}`}
                      name="role"
                      rules={{
                        required: !watch("inheritGroupPermissions") && t("user.management.formControl.required"),
                      }}
                      render={(field) => (
                        <CustomAutoComplete
                              {...field}
                              label={t("Role")}
                              optionLabel="name"
                              options={roles ?? []}
                              value={roles?.find(({ id }) => getValues("role")?.id === id)}
                              onChange={(value) => {
                                setValue("role", value);
                              }}
                              error={!!errors.role?.message}
                              helperText={!!errors.role && errors.role?.message}
                              disableCloseOnSelect={false}
                              disableClearable
                              disabled={disableFields || watch("inheritGroupPermissions")}
                          />
                      )}
                  />
              </FormControl>
            </Grid>
            {switchFields?.map((field, index) => (field?.isAuthorized ? (
              <Grid
                    item
                    xs={5}
                    className={classes.marginTop}
                    key={index}
                >
                <CustomSwitch
                      checked={watch(field?.fieldName)}
                      handleChange={() => setValue(
                          field?.fieldName,
                          field?.disabled ? watch(field?.fieldName) : !watch(field?.fieldName),
                      )}
                      label={t(field?.label)}
                  />
              </Grid>
            ) : null))}
          </Grid>
        </Grid>
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("Cancel")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            {t("next")}
          </CustomButton>
        </>
      ),
    },
    {
      content: (
        <ProcessAndTagSelector
          availableProcesses={availableProcesses}
          selectedProcesses={assignedProcesses}
          isDisabled={disableFields || watch("inheritGroupAutomations")}
          setAvailableProcesses={setAvailableProcesses}
          setSelectedProcesses={setAssignedProcesses}
          availableTags={availableTags}
          selectedTags={assignedTags}
          setAvailableTags={setAvailableTags}
          setSelectedTags={setAssignedTags}
        />
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("fleet.add.previous")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            {idUser ? t("user.button.update") : t("user.button.save")}
          </CustomButton>
        </>
      ),
    },
  ];

  return (
    <>
      <DialogWithTabs
            open={props?.open}
            entityBaseUrl={USERS_BASE_URL}
            moduleName="users"
            tabs={enabledTabs}
            disableFields={disableFields}
            editCondition={isFleetAdministrator(currentUser)}
            tabsContents={tabsContents.filter((item) => !item.disabledTab)}
            idItem={idUser}
            handleChange={handleChange}
            activeStep={activeStep}
            width={1000}
            height={disableFields ? 500 : 510}
      />
      {openMsgConfirm && (
        <ConfirmMessage
          message={
            idUser ? t("user.update.confirmMsg") : t("user.save.confirmMsg")
          }
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonConfirm={
            idUser ? t("user.button.update") : t("user.button.save")
          }
          buttonCancel={t("Cancel")}
          isLoading={isLoading}
        />
      )}
      {openMsgCancel && (
        <ConfirmMessage
          message={t("user.delete.discard")}
          openStart={openMsgCancel}
          onCancel={handleRejectCancelForm}
          onConfirm={handleAcceptCancelForm}
          buttonCancel={t("Cancel")}
          buttonConfirm={t("Discard")}
          isLoading={false}
        />
      )}
    </>
  );
};

export default AddUser;
