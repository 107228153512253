import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import FormControl from "@material-ui/core/FormControl";
import {
  fetchSrQueue,
  createOrUpdateSrQueue,
  fetchOrchestrators,
  fetchNotAssignedProcessesByOrchestrator,
  fetchResourceList,
  getRemainingLicencesForOrchestrator,
  processCountSchedules,
  fetchQueueResourcePendingExecutionsCount,
  hasQueuePendingExecutions,
} from "../../../../redux/actions/services/index";
import ProcessSelector from "../../ProcessSelector";
import get from "lodash/get";
import RobotSelector from "../../RobotSelector";
import { merge } from "lodash";
import { FETCH_CURRENT_USER, FETCH_NOT_ASSIGNED_PROCESSES_BY_ORCHESTRATOR, FETCH_ROBOTS } from "../../../../redux/constants";
import { useQuery } from "@redux-requests/react";
import { toast } from "react-toastify";
import CustomButton from "components/CustomButton";
import {
  handleInputChanges, formatInputOnBlur, formatInputOnFocus, getDefaultFormat,
 cannotBeAssignToQueue
} from "util";
import { QUEUES_BASE_URL } from "util/helpers/route.helper";
import CircularLoader from "components/Loaders/CircularLoader";
import DialogWithTabs from "components/FormComponents/DialogWithTabs";
import CustomAutoComplete, { CustomTextField } from "components/FormFields/CustomAutoComplete";
import CustomDialog from "components/CustomDialog";
import CustomSelectField from "components/FormFields/CustomSelectField";
import ConfirmMessage from "components/ConfirmMessage";
import { isPermitted } from "components/HasPermission";
import useStyles from "./style";

const QueueForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { queueId, mode = "add" } = useParams();
  const orchestrators = useSelector(({ requests }) => get(requests, "queries.FETCH_ORCHESTRATORS.data.content"));

  const { data: processes } = useQuery({ type: FETCH_NOT_ASSIGNED_PROCESSES_BY_ORCHESTRATOR });
  const resources = useQuery({ type: FETCH_ROBOTS })?.data?.content;
  const { data: currentUser } = useQuery({ type: FETCH_CURRENT_USER });
  const [queue, setQueue] = useState(null);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openMsgCancel, setOpenMsgCancel] = useState(false);
  const [availableProcesses, setAvailableProcesses] = useState([]);
  const [assignedProcesses, setAssignedProcesses] = useState([]);
  const [processesAction, setProcessesAction] = useState([]);
  const [availableResources, setAvailableResources] = useState([]);
  const [assignedResources, setAssignedResources] = useState([]);
  const [weShouldChooseResource, setWeShouldChooseResource] = useState(false)
  const [openChooseResourceDialog, setOpenChooseResourceDialog] = useState(false)
  const [dialogMsg, setDialogMsg] = useState();
  const [dialogMsg2, setDialogMsg2] = useState();
  const [selectedOrchestrator, setSelectedOrchestrator] = useState(null);
  const [remainingLicences, setRemainingLicences] = useState(0);
  const [openProcessAssignDialog, setOpenProcessAssignDialog] = useState(false);
  const [hasSchedules, setHasSchedules] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [selectedResource, setSelectedResource] = useState(null);
  const [draggedResource, setDraggedResource] = useState(null);
  const [resourceRequired, setResourceRequired] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [openPendingExecutionWarning, setOpenPendingExecutionWarning] = useState(false);
  const [processToUnAssign, setProcessToUnAssign] = useState(null);
  const [remainingLicensesLoading, setRemainingLicensesLoading] = useState(false);
  const [queueDataLoading, setQueueDataLoading] = useState(false);
  const [processesResourcesAssignData, setProcessesResourcesAssignData] = useState(null);
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    trigger,
    clearErrors,
    register,
    control,
    formState: { isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      nbLicences: 1,
      orchestrator: null,
    },
  });
  useEffect(() => {
    dispatch(fetchOrchestrators());
    if (mode !== "add") {
      setQueueDataLoading(true);
      dispatch(fetchSrQueue(queueId)).then((res) => {
        if (res.status === 200) {
          setValue("name", res.data.name);
          setValue("nbLicences", res.data.nbLicences);
          setQueue(res.data);
          setQueueDataLoading(false);
        }
      });
    }
  }, [currentUser, queueId, mode]);

  useEffect(() => {
    if (selectedOrchestrator) {
      if (mode === "add" || mode === "edit") {
        setRemainingLicensesLoading(true);
        dispatch(getRemainingLicencesForOrchestrator(selectedOrchestrator.id)).then((res) => {
          if (res.status === 200) {
            // In edit mode, we want to include the current queue's licences in the remaining licences
            const rl = mode === "edit" ? res.data + queue.nbLicences : res.data;
            setRemainingLicences(rl);
            setRemainingLicensesLoading(false);
          }
        })
      }
      dispatch(fetchNotAssignedProcessesByOrchestrator(selectedOrchestrator.id, mode === "add" ? null : queueId));
      dispatch(fetchResourceList({ orchestrator: [selectedOrchestrator] }));
    }
  }, [selectedOrchestrator]);

  useEffect(() => {
    if (orchestrators && queue) {
      setValue("orchestrator", orchestrators.find((o) => o.id === queue.orchestrator.id));
      setSelectedOrchestrator(queue.orchestrator);
    }
  }, [orchestrators, queue]);

  useEffect(() => {
    if (processes) {
      const _availableProcesses = queue ? processes.filter((p) => !queue.processes.find((pq) => pq.id === p.id)) : processes;
      setAvailableProcesses(_availableProcesses.filter((p) => !p.queue));
      setAssignedProcesses(queue ? processes.filter((p) => Boolean(queue.processes.find((pq) => pq.id === p.id))) : []);
    }
  }, [processes, queue]);

  useEffect(() => {
    if (resources) {
      setAvailableResources(queue ? resources.filter((p) => !queue.resources.find((rq) => rq.id === p.id)) : resources);
      setAssignedResources(queue ? resources.filter((r) => Boolean(queue.resources.find((rq) => rq.id === r.id))) : []);
    }
  }, [resources, queue]);

  const steps = [
    "queue.management.section.info",
    "queue.management.section.processes",
    "queue.management.section.resources",
  ];

  const tabs = [
    {
      id: "queue-management-section-info",
      label: "queue.management.section.info",
    },
    {
      id: "queue-management-section-processes",
      label: "queue.management.section.processes",
    },
    {
      id: "queue-management-section-resources",
      label: "queue.management.section.resources",
    },
  ];

  const disableFields = !(mode === "add" || mode === "edit");

  const handleCancel = () => {
    if (mode === "view" || !isDirty) {
      history.push(QUEUES_BASE_URL);
      return;
    }
    setOpenMsgCancel(true);
  };

  const handleBack = () => {
    if (activeStep <= 0) handleCancel();
    setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? 0 : prevActiveStep - 1));
  };

  const validateStep = (step) => {
    if (step === 1 && assignedProcesses.length === 0) {
      toast.error(t("queue.create.update.restriction"))
      return false
    }
    if (step === 2 && assignedResources.length === 0) {
      toast.error(t("queue.create.update.restriction"))
      return false
    }
    return true;
  }

  const handleSaveClick = async () => {
    const result = await trigger();
    if (result && validateStep(activeStep)) {
      if (activeStep === steps.length - 1) {
        setOpenMsgConfirm(true);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
    setIsLoading(false);
  };

  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    history.push(QUEUES_BASE_URL);
  };

  const handleRejectCancelForm = () => setOpenMsgCancel(false);
  const confirmSave = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = (data) => {
    let dataToSubmit = data;
    if (queue) {
      dataToSubmit = merge(queue, dataToSubmit);
    }
    dataToSubmit.orchestratorId = dataToSubmit.orchestrator.id;
    dataToSubmit.resources = assignedResources.map((r) => r.id);
    dataToSubmit.processes = assignedProcesses.map((p) => p.id);
    dataToSubmit.processActions = processesAction;
    dataToSubmit.nbLicences = +getDefaultFormat(dataToSubmit.nbLicences)
    setIsLoading(true);
    dispatch(createOrUpdateSrQueue(dataToSubmit)).then((res) => {
      if (res.status === 200) {
        setOpenMsgConfirm(false);
        history.push({
          pathname: QUEUES_BASE_URL,
        });
        toast.success(mode === "add" ? t("queue.management.form.save.success") : t("queue.management.form.update.success"))
      } else {
        setOpenMsgConfirm(false);
        setDialogMsg(mode === "add" ? t("queue.management.form.save.error") : t("queue.management.form.update.error"));
        toast.error(mode === "add" ? t("queue.management.form.save.error") : t("queue.management.form.update.error"))
      }
      setIsLoading(false);
    });
  };

  const handleChange = async (event, newValue) => {
    const result = await trigger();
    if (disableFields || result) {
      setActiveStep(newValue);
    }
  };

  const onAssignProcess = (process) => assignUnassingProcess("assign", process);

  const onUnAssignProcess = (process) => dispatch(hasQueuePendingExecutions(queueId, process.id)).then((res) => {
      if (res.data) {
        setOpenPendingExecutionWarning(true);
        setProcessToUnAssign(process);
        return true;
      }
      return assignUnassingProcess("unassign", process);
    });
  const confirmDeletePendingExecutions = () => {
    setOpenPendingExecutionWarning(false);
    assignUnassingProcess("unassign", processToUnAssign);
  }
  const pickResource = () => {
    if (!selectedResource)
    {
      setResourceRequired(true);
      return;
    }

    setProcessesAction((prevState) => prevState?.map((item, index) => {
      if (index === prevState.length - 1) item.resourceId = selectedResource
      return item;
    }))
    setOpenChooseResourceDialog(false)
    setOpenProcessAssignDialog(false)
    setWeShouldChooseResource(false)
  }

  const assignUnassingProcess = (action, process) => {
    setDialogMsg("");
    setSelectedProcess(process);

    // If the process is moved back to its original emplacement - do nothing
    const originalAvailableProcessList = queue ? processes.filter((p) => Boolean(queue.processes.find((pq) => pq.id === p.id))) : [];
    if (originalAvailableProcessList) {
      const originalAssigned = originalAvailableProcessList.filter((p) => p.id === process.id);
      if ((action === "assign" && originalAssigned && originalAssigned.length > 0)
        || (action === "unassign" && (!originalAssigned || originalAssigned.length === 0))) {
        dispatch(processCountSchedules(process.id)).then((res) => {
          if (res.status === 200 && (res?.data?.processesIds?.length > 1 || res?.data?.resourcesIds?.length > 0)) {
            setProcessesResourcesAssignData({
                processes: res?.data?.processesIds,
                resources: res?.data?.resourcesIds,
              });
            } });
        postAssignProcessAction("RESET", process.id, action);
        return true;
      }
    }
    dispatch(processCountSchedules(process.id)).then((res) => {
      if (res.status === 200) {
        if (!res.data.migrationPossible) {
          toast.warn(t("process.migration.not.possible"));
          return false;
        }
            setProcessesResourcesAssignData({
              processes: res?.data?.processesIds,
              resources: res?.data?.resourcesIds,
            });

        const nbSchedules = res.data.countSchedules;
        if (res.data.countSchedulesWithoutResources > 0 && action === "unassign") setWeShouldChooseResource(true)

        setOpenProcessAssignDialog(action);
        setHasSchedules(nbSchedules !== 0);
        setDialogMsg(t(`queue.management.${action}.process.part1`, { this_process: t("thisProcess") }));
        setDialogMsg2(nbSchedules === 0 ? null
          : t(`queue.management.${action}.process.part2`, {
            count: nbSchedules,
            this_process: t("thisProcess"),
            at_its: t("atIts"),
            nbSchedules
          }));
        return true;
      }
      return false;
    });
    return false;
  }

  const onUnAssignResource = (resource) => {
    setDraggedResource(resource);
    dispatch(fetchQueueResourcePendingExecutionsCount(queueId, resource.id))
        .then((res) => {
          const { data } = res;
          const scheduledExecs = data?.SCHEDULED ?? 0;
          const countExecutions = (data?.MANUALLY ?? 0) + scheduledExecs;
          if (countExecutions === 0) {
            onProcessesResourcesAssign("unassign")
            return true
          }
          const mssg = t("queue.management.resource.has.pendingExecutions", { count: countExecutions })
          toast.error(mssg, {
            onClose: () => {
              if (scheduledExecs > 0) {
                toast.error(
                    t("queue.management.resource.has.scheduled.pendingExecutions", { count: scheduledExecs })
                )
              }
            }
          });

          return false;
        })
  }

  const onAssignResource = (resource) => {
    setDraggedResource(resource);
    onProcessesResourcesAssign("assign");
    return true
  }

  const onProcessesResourcesAssign = (dragAction = "assign") => {
    if (dragAction === "assign") {
      setAssignedProcesses((prevState) => ([...prevState, ...availableProcesses.filter((p) => processesResourcesAssignData?.processes?.includes(p?.id) && !prevState?.map(({ id }) => id)?.includes(p?.id))]))
      setAvailableProcesses((prevState) => (prevState.filter((p) => !processesResourcesAssignData?.processes?.includes(p?.id))))
      setAssignedResources((prevState) => ([...prevState, ...availableResources.filter((p) => processesResourcesAssignData?.resources?.includes(p?.id) && !prevState?.map(({ id }) => id)?.includes(p?.id))]))
      setAvailableResources((prevState) => (prevState.filter((p) => !processesResourcesAssignData?.resources?.includes(p?.id))))
    } else {
      setAvailableProcesses((prevState) => ([...prevState, ...assignedProcesses.filter((p) => processesResourcesAssignData?.processes?.includes(p?.id) && p?.id !== selectedProcess?.id)]))
      setAssignedProcesses((prevState) => ([...prevState.filter((p) => !processesResourcesAssignData?.processes?.includes(p?.id))]))
      setAvailableResources((prevState) => ([...prevState, ...assignedResources.filter((p) => processesResourcesAssignData?.resources.includes(p?.id) && p?.id !== draggedResource?.id)]))
      setAssignedResources((prevState) => ([...prevState.filter((p) => !processesResourcesAssignData?.resources?.includes(p?.id))]))
    }
  }

  const postAssignProcessAction = (action, processId = selectedProcess?.id, dragAction = "assign") => {
    const linkedProcesses = processesResourcesAssignData?.processes ?? [processId];
    const newProcessesAction = [];
    linkedProcesses?.forEach((process) => {
      setProcessesAction((prevState) => prevState.filter((el) => el?.id !== process))
      switch (action) {
        case "DELETE":
          newProcessesAction.push({ id: process, action });
          break;
        case "KEEP": {
          newProcessesAction.push({ id: process, action });
          if (weShouldChooseResource) setOpenChooseResourceDialog(true);
          break;
        }
        default:
          break;
      }
    })
    setProcessesAction((prevState) => [...prevState, ...newProcessesAction]);
    onProcessesResourcesAssign(dragAction);
    setOpenProcessAssignDialog(false)
  }
  const redragProcessToOrigin = (processId) => {
    const processAssigned = assignedProcesses.find(({ id }) => id === processId)
    if (processAssigned)
    {
      setAssignedProcesses((prevState) => prevState.filter(({ id }) => id !== processId))
      setAvailableProcesses((prevState) => [...prevState, processAssigned])
    }
    else
    {
      const processAvailable = availableProcesses.find(({ id }) => id === processId)
      setAssignedProcesses((prevState) => [...prevState, processAvailable])
      setAvailableProcesses((prevState) => prevState.filter(({ id }) => id !== processId))
    }
  }

  const actions = (
    <>
      <CustomButton view="cancelModal" onClick={handleBack}>
        {activeStep <= 0 ? t("Cancel") : t("fleet.add.previous")}
      </CustomButton>
      <CustomButton
        view="primary"
        startIcon={
          remainingLicensesLoading && (
            <CircularProgress color="inherit" size={13} />
          )
        }
        onClick={handleSaveClick}
        disabled={remainingLicensesLoading}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {activeStep === steps.length - 1
          ? queueId
            ? t("queue.management.update")
            : t("queue.management.add")
          : t("next")}
      </CustomButton>
    </>
  );

  const tabsContents = [
    {
      content: queueDataLoading ? (
        <CircularLoader height="100%" />
      ) : (
        <Box minWidth={720} pt={2}>
          <Grid item xs={12} container className={classes.formRow}>
            <CustomTextField
              {...register("name", {
                required: t("queue.management.form.validation.required"),
              })}
              valued={getValues("name")}
              id="name"
              label={`${t("queue.management.name")} *`}
              fullWidth
              disabled={disableFields}
              InputLabelProps={{
                shrink: !!getValues("name"),
              }}
              error={!!errors.name?.message}
              helperText={!!errors.name && errors.name?.message}
              onChange={(event) => {
                clearErrors("name");
                setValue("name", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} container className={classes.formRow}>
            <FormControl fullWidth>
              <Controller
                control={control}
                name="orchestrator"
                rules={{
                  required: t("queue.management.form.validation.required"),
                }}
                render={(field) => (
                  <CustomAutoComplete
                    {...field}
                    value={field.field.value}
                    label={`${t("queue.management.orchestrator")} *`}
                    optionLabel="name"
                    options={
                      orchestrators?.filter(
                        (o) => !cannotBeAssignToQueue(o?.orchestratorType)
                      ) ?? []
                    }
                    onChange={(value) => {
                      clearErrors("orchestrator");
                      setSelectedOrchestrator(value);
                      setValue("orchestrator", value)
                    }}
                    error={!!errors.orchestrator?.message}
                    helperText={
                      !!errors.orchestrator && errors.orchestrator?.message
                    }
                    disabled={disableFields}
                    disableClearable
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} container className={classes.formRow}>
            <CustomTextField
              {...register("nbLicences", {
                required: t("queue.management.form.validation.required"),
                validate: (nb) => {
                  if (disableFields) return true;
                  if (getDefaultFormat(nb) === 0) return t("queue.create.update.restriction");
                  if (getDefaultFormat(nb) < 0) { return t(
                      "queue.management.form.validation.negative-nb-licences"
                    ); }
                  if (getDefaultFormat(nb) > remainingLicences) { return t(
                      "queue.management.form.validation.nb-licences-bigger-than-remaining",
                      { count: remainingLicences }
                    ); }
                  return true;
                },
              })}
              valued={getValues("nbLicences")}
              id="nbLicences"
              type="text"
              label={`${t("queue.management.nb-licences")} *`}
              fullWidth
              disabled={disableFields || !selectedOrchestrator}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.nbLicences?.message}
              helperText={!!errors.nbLicences && errors.nbLicences?.message}
              onChange={(event) => {
                clearErrors("nbLicences");
                setValue("nbLicences", +event.target.value);
              }}
              onInput={(e) => handleInputChanges(e, 9)}
              onBlur={() => formatInputOnBlur("nbLicences", { getValues, setValue })}
              onFocus={() => formatInputOnFocus("nbLicences", { getValues, setValue })}
              autoComplete="off"
              /* eslint-disable-next-line react/jsx-no-duplicate-props */
              inputProps={{
                min: 0,
              }}
            />
          </Grid>
        </Box>
      ),
      actions,
    },
    {
      content: (
        <Box minHeight={370} minWidth={720}>
          <ProcessSelector
            availableProcesses={availableProcesses}
            selectedProcesses={assignedProcesses}
            setAvailableProcesses={setAvailableProcesses}
            setSelectedProcesses={setAssignedProcesses}
            isDisabled={disableFields}
            onAssignProcess={onAssignProcess}
            onUnAssignProcess={onUnAssignProcess}
          />
        </Box>
      ),
      actions,
    },
    {
      content: (
        <Box minHeight={370} minWidth={720}>
          <RobotSelector
            availableResources={availableResources}
            selectedResources={assignedResources}
            setAvailableResources={setAvailableResources}
            setSelectedResources={setAssignedResources}
            isDisabled={disableFields}
            onUnAssignResource={onUnAssignResource}
            onAssignResource={onAssignResource}
          />
        </Box>
      ),
      actions,
    },
  ];

  const popupTitles = dialogMsg?.split(".");

  useEffect(() => {
    setSelectedProcess(null);
    setDraggedResource(null);
  }, [activeStep]);

  return (
    <div className={classes.root}>
      <DialogWithTabs
        open
        entityBaseUrl={QUEUES_BASE_URL}
        moduleName="queue"
        tabs={tabs}
        disableFields={disableFields}
        tabsContents={tabsContents}
        idItem={queueId}
        handleChange={handleChange}
        editCondition={isPermitted(currentUser, "Edit SR Queue")}
        activeStep={activeStep}
        height={disableFields ? 470 : 510}
      />

      {openMsgConfirm && (
        <ConfirmMessage
          message={
            queueId
              ? t("queue.management.update.confirm-msg")
              : t("queue.management.save.confirm-msg")
          }
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonConfirm={
            queueId ? t("queue.management.update") : t("queue.management.add")
          }
          buttonCancel={t("Cancel")}
          isLoading={isLoading}
        />
      )}
      {openMsgCancel && (
        <ConfirmMessage
          message={t("user.delete.discard")}
          openStart={openMsgCancel}
          onCancel={handleRejectCancelForm}
          onConfirm={handleAcceptCancelForm}
          buttonCancel={t("Cancel")}
          buttonConfirm={t("Discard")}
          isLoading={false}
        />
      )}
      {openPendingExecutionWarning && (
        <ConfirmMessage
          message={t("pending.executions.warning")}
          openStart={openPendingExecutionWarning}
          onCancel={() => {
            setOpenPendingExecutionWarning(false);
            redragProcessToOrigin(processToUnAssign.id);
          }}
          onConfirm={confirmDeletePendingExecutions}
          buttonCancel={t("Cancel")}
          buttonConfirm={t("Continue")}
          isLoading={false}
        />
      )}
      {openProcessAssignDialog && (
        <CustomDialog
          open={openProcessAssignDialog && !openChooseResourceDialog}
          title={
            popupTitles && popupTitles.length > 1 ? popupTitles?.[0] : null
          }
          contentText={
            popupTitles && popupTitles.length > 1
              ? popupTitles?.[1]
              : popupTitles?.[0]
          }
          dialogHelper={dialogMsg2}
          aria-labelledby="confirm_message-dialog-title"
          maxWidth="xs"
          actionElements={[
            {
              label: t("Cancel"),
              onClick: () => {
                redragProcessToOrigin(selectedProcess.id);
                setOpenProcessAssignDialog(false);
              },
              view: "cancelModal",
            },
            {
              label: t("do.not.migrate"),
              onClick: () => postAssignProcessAction("DELETE", null, openProcessAssignDialog),
              view: "cancelOutlined",
              className: classes.testConnectionButton,
              hidden: !hasSchedules,
            },
            {
              label: t("migrate"),
              onClick: () => postAssignProcessAction("KEEP", null, openProcessAssignDialog),
              view: "primary",
              hidden: !hasSchedules,
            },
            {
              label: t("Validate"),
              onClick: () => setOpenProcessAssignDialog(false),
              view: "primary",
              hidden: hasSchedules,
            },
          ]}
        />
      )}

      {weShouldChooseResource && (
        <CustomDialog
          open={openChooseResourceDialog}
          maxWidth="xs"
          actionElements={[
            {
              label: t("fleet.button.cancel"),
              onClick: () => {
                setProcessesAction((prevState) => prevState.filter(
                    (item, index) => index !== prevState.length - 1
                  ));
                setOpenChooseResourceDialog(false);
              },
              view: "cancelModal",
            },
            {
              label: t("fleet.button.save"),
              onClick: pickResource,
              view: "primary",
            },
          ]}
          contentText={t("queue.management.choose.resource")}
        >
          <CustomSelectField
            options={resources ?? []}
            value={selectedResource}
            optionLabel="resourceDisplayName"
            optionValue="id"
            onChange={(e) => {
              setResourceRequired(false);
              setSelectedResource(e.target.value);
            }}
            error={resourceRequired}
            helperText={resourceRequired && t("Field must not be empty")}
            isCustom
            fullWidth
          />
        </CustomDialog>
      )}
    </div>
  );
};

export default QueueForm;
