import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import DialogActions from "@material-ui/core/DialogActions";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  fetchProcessesSortedByUsage,
  fetchShared,
  saveCost,
  saveShardAll,
  fetchAllocations,
} from "redux/actions/services";
import useStyles from "pages/AutomationsPending/style";
import InformationIcon from "components/InformationIcon";
import { CustomTextField } from "components/FormFields/CustomAutoComplete";
import {
  formatInputOnBlur,
  formatInputOnFocus,
  getDefaultFormat,
  handleInputChanges,
} from "util";
import CostDistribution from "pages/AutomationsPending/ConfigurationSteps/FinancialParameters/CostDistribution";
import { INITIAL_COST, RECURRING_LABEL, SHARED_TYPE } from "pages/Financial";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import CustomButton from "components/CustomButton";
import CustomDialog from "components/CustomDialog";
import SlaItemTitle from "pages/Services/components/SlaInfo/SlaItemTitle";
import CostAllocationForm from "pages/Financial/CostAllocations";
import CostShared from "pages/Financial/CostShared";

export default function FinancialParameters() {
  const form = useFormContext();
  const financialForm = useForm();
  const { t } = useTranslation();
  const classes = useStyles()();
  const [processesData, setProcessesData] = useState(null);
  const [allocationLoading, setAllocationLoading] = useState(false);
  const [allocationsData, setAllocationsData] = useState([]);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleAddClick = () => {
    financialForm.setValue("sharedData", [
      ...financialForm.getValues("sharedData"),
      INITIAL_COST,
    ]);
  };
  const handleDeleteClick = (index) => {
    financialForm.setValue(
      "sharedData",
      financialForm.watch("sharedData").filter((_, i) => i !== index)
    );
  };
  const getProcessesSortedByUsage = () => {
    dispatch(fetchProcessesSortedByUsage()).then((data) => {
      if (data.data) setProcessesData(data.data);
    });
  };

  const fetchAllocationsData = () => {
    if (form.getValues("id")) {
        dispatch(fetchAllocations(form.getValues("id"))).then((response) => {
            setAllocationLoading(false);
            setAllocationsData(response.data.list);
        }) }
  };

  const fetchSharedData = () => {
    dispatch(fetchShared()).then((sharedResponse) => {
      const sharedAllocations = sharedResponse.data.list || [];
      const groupedSharedData = sharedAllocations?.reduce((acc, item) => {
        const costAllocationsId = item.costAllocations.id;
        if (!acc[costAllocationsId]) {
          acc[costAllocationsId] = {
            costAllocations: item.costAllocations,
            sharedData: [],
          };
        }
        acc[costAllocationsId].sharedData.push(item);
        return acc;
      }, {});
      const groupedSharedDataArray = Object.values(groupedSharedData);
      const allowed = groupedSharedDataArray.filter(({ sharedData }) => sharedData
          .map(({ processId }) => processId)
          .includes(form.getValues("id")));
      financialForm.setValue("financialDto.sharedData", allowed);
    });
  };
  const onSuccess = () => toast.success(t("changes.saved.successfully"));

  const onError = () => toast.error(t("changes.saving.failed"));
  const saveData = async (data) => {
    const { category, type } = financialForm.watch();
    const savedCost = await dispatch(
      saveCost({
        ...data,
        processId: form.getValues("id"),
        category,
        type,
      })
    );
    await Promise.all([
      dispatch(
        saveShardAll(
          data?.sharedData?.map((processAllocation) => ({
            ...processAllocation,
            costAllocations: {
              name: data.name,
              category,
              type,
              allocatedAmount: Number(data?.allocatedAmount),
              processId: form.getValues("id"),
              id: savedCost.data.id,
            },
          })),
          savedCost.data.id,
          onSuccess,
          onError
        )
      ),
    ]);
  };
  const saveOnly = async (data) => {
    const { category, type } = financialForm.watch();
    if (data.type === SHARED_TYPE) {
      await saveData(data);
      fetchSharedData();
      fetchAllocationsData();
      setOpen(false);
    }
    if (data.type === "exclusive") {
      dispatch(
        saveCost(
          {
            ...data,
            processId: form.getValues("id"),
            category,
            type,
          },
          () => {
            onSuccess();
            fetchSharedData();
            fetchAllocationsData();
          },
          onError
        )
      );
      setOpen(false);
    }
  };
  const onSubmit = async (data) => {
    const tempSharedData = [];
    if (data?.sharedData) { data.sharedData?.forEach((el) => tempSharedData.push({
          ...el,
          weight: +getDefaultFormat(el?.weight),
          allocatedAmount: +getDefaultFormat(el?.allocatedAmount),
        })); }
    const dataToSend = {
      ...data,
      allocatedAmount: +getDefaultFormat(data?.allocatedAmount),
      sharedData: tempSharedData,
    };
    saveOnly(dataToSend);
    setOpen(false);
  };

  useEffect(() => {
    setAllocationLoading(true);
    fetchSharedData();
    fetchAllocationsData();
    getProcessesSortedByUsage();
  }, []);
  return (
    <Box>
      <SlaItemTitle title={t("Financial Parameters")} />
      <CustomTextField
        {...form.register("financialDto.fteItemTime", {
          required: t("field.is.required", {
            field: t("Time Saved per Item"),
          }),
        })}
        id="name"
        onBlur={(field) => formatInputOnBlur(field, form)}
        onFocus={(field) => formatInputOnFocus(field, form)}
        onInput={(e) => handleInputChanges(e, 9)}
        label={
          <InformationIcon
            titleContent={t("Time Saved per Item")}
            originalContent={t("Time Saved per Item")}
            placement="right"
          />
        }
        maxLength={4}
        min={0}
        error={!!form.formState.errors?.financialDto?.fteItemTime?.message}
        valued={form.getValues("financialDto.fteItemTime")}
        helperText={
          form.formState.errors?.financialDto?.fteItemTime
          && form.formState.errors.financialDto?.fteItemTime?.message
        }
        className={classes.textField}
        fullWidth
      />
      <CustomTextField
        {...form.register("financialDto.fteExecutionTime", {
          required: t("field.is.required", {
            field: t("Time Saved per Execution"),
          }),
        })}
        id="fteExecutionTime"
        onBlur={(field) => formatInputOnBlur(field, form)}
        onFocus={(field) => formatInputOnFocus(field, form)}
        onInput={(e) => handleInputChanges(e, 9)}
        label={
          <InformationIcon
            titleContent={t("Time Saved per Execution")}
            originalContent={t("Time Saved per Execution")}
            placement="right"
          />
        }
        maxLength={4}
        min={0}
        error={!!form.formState.errors?.financialDto?.fteExecutionTime?.message}
        valued={!!form.getValues("financialDto.fteExecutionTime")}
        helperText={
          form.formState.errors?.financialDto?.fteExecutionTime
          && form.formState.errors?.financialDto?.fteExecutionTime?.message
        }
        className={classes.textField}
        fullWidth
      />
      <CustomTextField
        {...form.register("financialDto.fteCost", {
          required: t("field.is.required", {
            field: t("FTE cost"),
          }),
        })}
        id="fteCost"
        onBlur={(field) => formatInputOnBlur(field, form)}
        onFocus={(field) => formatInputOnFocus(field, form)}
        onInput={(e) => handleInputChanges(e, 9)}
        label={
          <InformationIcon
            titleContent={t("FTE cost")}
            originalContent={t("FTE cost")}
            placement="right"
          />
        }
        maxLength={4}
        min={0}
        InputLabelProps={{
          shrink: !!form.watch("financialDto.fteCost"),
        }}
        error={!!form.formState.errors.financialDto?.fteCost?.message}
        valued={!!form.getValues("financialDto.fteCost")}
        helperText={
          form.formState.errors.financialDto?.fteCost
          && form.formState.errors.financialDto?.fteCost?.message
        }
        className={classes.textField}
        fullWidth
      />
      <Divider orientation="horizontal" className={classes.divider} />
      <CostDistribution
        sharedData={form.getValues("financialDto.sharedData")}
        handleClickOpen={() => setOpen(true)}
        allocationLoading={allocationLoading}
        allocationsData={allocationsData}
      />
      <FormProvider {...financialForm}>
        <CustomDialog
          open={open}
          customWidth={1000}
          maxWidth={1000}
          formProps={{
            ...financialForm,
            onSubmit: financialForm.handleSubmit((newData) => {
              onSubmit(newData);
            }),
          }}
        >
          <DialogTitle>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {t("Cost Configuration")}
              <CustomCloseButton
                edge="end"
                aria-label="cancel"
                onClick={() => setOpen(false)}
              />
            </Box>
          </DialogTitle>
          <DialogContent className={classes.dialogBody}>
            <Grid container justify="center" spacing={6}>
              <Grid item xs={4}>
                <CostAllocationForm
                  showDates={
                    financialForm.watch("category") === RECURRING_LABEL
                  }
                />
              </Grid>
              <Grid item xs={8}>
                <CostShared
                  showCostShared={
                    financialForm.watch("type") === SHARED_TYPE
                    && +getDefaultFormat(financialForm.watch("allocatedAmount"))
                      > 0
                  }
                  processAllocations={financialForm.getValues("sharedData")}
                  processesData={processesData}
                  handleAddClick={handleAddClick}
                  handleDeleteClick={handleDeleteClick}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CustomButton
              view=""
              className={classes.popupBtnCancel}
              onClick={() => setOpen(false)}
            >
              {t("Cancel")}
            </CustomButton>
            <CustomButton
              view="primary"
              className={classes.popupBtnSave}
              onClick={financialForm.handleSubmit((newData) => {
                onSubmit(newData);
              })}
            >
              {t("Save ")}
            </CustomButton>
          </DialogActions>
        </CustomDialog>
      </FormProvider>
    </Box>
  );
}
