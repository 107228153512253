/**
 * @module BackendEntities : DefaultBackendEntity
 */

/**
 * Route api microservice
 * @type {string}
 */
export const routeApi = "api"

/**
 * Admin microservice entity
 * @type {string}
 */
export const adminMicroserviceEntity = "admin"

/**
 * Orchestrator entity
 * @type {string}
 */
export const orchestratorEntity = "orchestrator"
/**
 * Guacamole service
 * @type {string}
 */
export const guacamole = "guacamole"

/**
 * Dashboard entity
 * @type {string}
 */
export const resourceProductivity = "resource-productivity"
export const robotProductivity = "robot-productivity"

/**
 * Group entity
 * @type {string}
 */
export const groupEntity = "fleet"
export const groupsEntity = "fleets"

/**
 * Automation entity
 * @type {string}
 */
export const automationEntity = "process"
export const automationsEntity = "processes"
export const restriction = "restriction"

/**
 * Alert entity
 * @type {string}
 */
export const alertEntity = "alert"

/**
 * User entity
 * @type {string}
 */
export const userEntity = "user"
export const currentUser = "current-user"

/**
 * Audit entity
 * @type {string}
 */
export const auditEntity = "audit"
export const logs = "logs"

/**
 * Queue entity
 * @type {string}
 */
export const srqueuesEntity = "srqueues"

/**
 * Tags entity
 * @type {string}
 */
export const tagsEntity = "tags"

/**
 * Financial entity
 * @type {string}
 */
export const financialEntity = "financial"
export const shared = "shared"

/**
 * Resource entity
 * @type {string}
 */
export const resourcesEntity = "resources"

/**
 * Permissions entity
 * @type {string}
 */
export const permissionEntity = "permission"
export const template = "template"

/**
 * Analytics entity
 * @type {string}
 */
export const analyticsEntity = "analytics"
export const presets = "presets"
export const customKpi = "custom-kpi"
export const customKpis = "custom-kpis"

/**
 * Exception entity
 * @type {string}
 */
export const exceptionsEntity = "exceptions"
export const markAsDone = "markAsDone"
export const reopen = "reopen"
export const release = "release"
export const assign = "assign"
export const take = "take"
export const rulesEntity = "rules"

/**
 * Export entity
 * @type {string}
 */
export const exportEntity = "export"
export const exportedzip = "exportedzip"
export const deleteexported = "deleteexported"

export default routeApi
