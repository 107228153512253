import {
    EXIST_PROCESS_RESTRICTION_PRIORITY, FETCH_FLEET_PROCESSES,
    FETCH_CONNECTED_USER_PROCESSES, FETCH_NOT_ASSIGNED_PROCESSES_BY_ORCHESTRATOR,
    FETCH_PLANNER_PROCESSES_LIST, FETCH_PROCESS_DATA_MAPPING,
    FETCH_PROCESS_PLANNER,
    FETCH_PROCESS_RESTRICTION,
    FETCH_PROCESSES,
    FETCH_PROCESSES_BY_FLEET, FETCH_PROCESSES_BY_ORCHESTRATOR,
    FETCH_PROCESSES_NAMES,
    FETCH_QUEUE_PROCESSES,
    FETCH_ROBOT_PROCESSES,
    FETCH_USER_PROCESSES,
    SAVE_PROCESS_RESTRICTION,
    UPDATE_PROCESS, UPDATE_PROCESS_DATA_MAPPING,
    UPDATE_PROCESS_DESCRIPTION,
    UPDATE_PROCESS_ICON,
    UPDATE_PROCESS_RISK_TIME, UPDATE_ROBOT, VALIDATE_PROCESS_ASSIGN_FROM_FLEET,
    FETCH_PROCESS_DETAILS, FETCH_PROCESSES_SORTED_BY_USAGE,
    FETCH_UNAVAILABLE_PRIORITIES, UPDATE_PROCESS_PARTIALLY, FETCH_PROCESS_INPUTS, FETCH_PROCESSES_FOR_FILTERS,
    FETCH_NOT_ASSIGNED_TAGS, FETCH_PROCESSES_FOR_ADMINISTRATION,
} from "../../constants";
import {
 URL_FLEET_v2, URL_PROCESSES, URL_PROCESSES_DATA_MAPPING, URL_ROBOT
} from "../../constants/endpoints";
import { automationsEntity, restriction } from "util/configs/backendEntities";

export const fetchProcesses = (processName, fleetIds, tagsIds, page, size, sort) => ({
    type: FETCH_PROCESSES,
    request: {
        url: encodeURI(`${URL_PROCESSES}${processName ? `?processName=${processName}` : ""}`),
        params: {
            fleetIds: fleetIds?.join(),
            tagsIds: tagsIds?.join(),
            page,
            size,
            sort
        },
    },
});
export const fetchProcessesSortedByUsage = (fleetIds = []) => ({
    type: FETCH_PROCESSES_SORTED_BY_USAGE,
    request: {
        url: `${URL_PROCESSES}sorted-by-usage`,
        params: {
            fleetIds: fleetIds.join()
        }
    },
});

export const fetchProcessesForFilters = (tagsIds = [], fleetIds = []) => ({
    type: FETCH_PROCESSES_FOR_FILTERS,
    request: {
        url: `${URL_PROCESSES}filter`,
        params: {
            tagsIds: tagsIds.join(),
            fleetIds: fleetIds.join(),
        }
    },
});
export const fetchProcessesForAdministration = (params) => ({
    type: FETCH_PROCESSES_FOR_ADMINISTRATION,
    request: {
        url: `${URL_PROCESSES}administration`,
        params: {
            tagsIds: params?.tagsIds.join(),
            fleetIds: params?.fleetIds.join(),
        }
    },
});
export const fetchProcessesDetails = (id) => ({
    type: FETCH_PROCESS_DETAILS,
    request: {
        url: `${URL_PROCESSES}processdetails/${id}`,
    },
});

export const fetchUnassignedProcesses = (fleetId) => ({
    type: FETCH_PROCESSES,
    request: {
        url: `${URL_FLEET_v2}/${automationsEntity}/not-assigned`,
        params: {
            fleetId
        }
    },
});
export const fetchUnassignedTags = (fleetId) => ({
    type: FETCH_NOT_ASSIGNED_TAGS,
    request: {
        url: `${URL_FLEET_v2}/tags/not-assigned`,
        params: {
            fleetId
        }
    },
});
export const fetchUserProcesses = (userId, mergeWithTagsProcesses = true) => ({
    type: FETCH_USER_PROCESSES,
    request: {
        url: `${URL_PROCESSES}list/assigned/${userId}`,
        params: {
            mergeWithTagsProcesses
        }
    },
});

export const fetchConnectedUserProcesses = (fleetIds = []) => ({
    type: FETCH_CONNECTED_USER_PROCESSES,
    request: {
        url: `${URL_PROCESSES}list/assigned`,
        params: {
            fleetIds: fleetIds.join(),
        }
    },
});
export const fetchProcessesName = (processes = []) => ({
    type: FETCH_PROCESSES_NAMES,
    request: { url: `${URL_PROCESSES}names?processids=${processes?.join()}` },
});
export const startProcess = ({ id, resourceName, inputs }) => ({
    type: UPDATE_PROCESS,
    request: {
        url: `${URL_PROCESSES}${id}/start`,
        method: "put",
        data: {
            inputs,
            resourceName,
        },
    },
    meta: {
        onSuccess: (response) => response,
    },
});
export const fetchProcessesByFleet = (id) => ({
    type: FETCH_PROCESSES_BY_FLEET,
    request: {
        url: `${URL_PROCESSES}list/fleet/${id}`,
        method: "get",
    },
});
export const saveProcessRestriction = (processId, restrictionData) => ({
    type: SAVE_PROCESS_RESTRICTION,
    request: {
        url: `${URL_PROCESSES}${processId}/${restriction}`,
        method: "post",
        data: { ...restrictionData },
    },
    meta: {
        onSuccess: (response) => response,
    },
});
export const fetchProcessRestriction = (processId) => ({
    type: FETCH_PROCESS_RESTRICTION,
    request: {
        url: `${URL_PROCESSES}${processId}/${restriction}`,
        method: "get",
    },
});
export const checkIfPrioriryAlreadyExists = (processId, priority) => {
    const p = isNaN(priority) ? null : priority;
    return {
        type: EXIST_PROCESS_RESTRICTION_PRIORITY,
        request: {
            url: `${URL_PROCESSES}${processId}/${restriction}/priority`,
            method: "get",
            params: { priority: p },
        },
    };
};
export const fetchUnavailablePriorities = (processId) => ({
        type: FETCH_UNAVAILABLE_PRIORITIES,
        request: {
            url: `${URL_PROCESSES}${processId}/${restriction}/priority/taken`,
            method: "get",
        },
    });
export const fetchAssignedProcessesByResource = (resourceId) => ({
    type: FETCH_ROBOT_PROCESSES,
    request: {
        url: `${URL_ROBOT}${automationsEntity}/${resourceId}`,
        method: "GET",
    },
});
export const fetchAssignedProcessesByQueue = (queueId, onSuccess) => ({
    type: FETCH_QUEUE_PROCESSES,
    request: {
        url: `${URL_PROCESSES}queue/${queueId}`,
        method: "GET",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
    }
});
export const updateProcess = (id, data, onSuccess, onError) => ({
    type: UPDATE_PROCESS,
    request: {
        url: `${URL_PROCESSES}${id}`,
        method: "put",
        data,
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    }
});
export const updateprocessdescription = (id, data) => ({
    type: UPDATE_PROCESS_DESCRIPTION,
    request: {
        url: `${URL_PROCESSES}description/${id}`,
        method: "put",
        data,
    },
    meta: {
        onSuccess: (response) => response,
    },
});
export const updateProcessPartially = (id, data) => ({
    type: UPDATE_PROCESS_PARTIALLY,
    request: {
        url: `${URL_PROCESSES}${id}`,
        method: "patch",
        data,
    },
    meta: {
        onSuccess: (response) => response,
    },
});
export const fetchPlannerProcessesList = (fleetIds = []) => ({
    type: FETCH_PLANNER_PROCESSES_LIST,
    request: {
        url: `${URL_PROCESSES}list/planner`,
        method: "get",
        params: {
            fleetIds: fleetIds.join(),
        }
    },
});
export const fetchProcessesExecutionsPlanner = (
    date,
    orchestratorId,
    processesId = [],
    resourcesIds = [],
    tags = [],
    fleetsIds = []
) => ({
    type: FETCH_PROCESS_PLANNER,
    request: {
        url: `${URL_PROCESSES}planner`,
        params: {
            date,
            orchestratorId,
            processesId: processesId?.join(","),
            tagsIds: tags?.join(),
            resourcesIds: resourcesIds?.join(),
            fleetsIds: fleetsIds?.join()
        },
    },
});
export const updateProcessRiskTime = (riskTime) => ({
    type: UPDATE_PROCESS_RISK_TIME,
    request: {
        url: `${URL_PROCESSES}riskTime`,
        method: "put",
        data: riskTime,
    },
});
export const updateProcessIcon = (processId, iconPath) => ({
    type: UPDATE_PROCESS_ICON,
    request: {
        url: `${URL_PROCESSES}logos/${processId}`,
        data: iconPath,
        method: "put",
    },
});
export const validateUnassignProcessFromFleet = (processId) => ({
    type: VALIDATE_PROCESS_ASSIGN_FROM_FLEET,
    request: {
        url: `${URL_PROCESSES}validateUnassignProcessFromFleet/${processId}`,
    },
});

export const fetchFleetProcesses = (clentId) => ({
    type: FETCH_FLEET_PROCESSES,
    request: { url: `${URL_PROCESSES}list/assignedToFleet/${clentId}` },
});
export const updateProcessStatus = (processId, newStatus) => ({
    type: "UPDATE_PROCESS_STATUS",
    request: {
        url: `${URL_PROCESSES}status/${processId}`,
        method: "put",
        params: {
            status: newStatus,
        },
    },
});
export const fetchProcessesByOrchestrator = (orchestratorId) => ({
    type: FETCH_PROCESSES_BY_ORCHESTRATOR,
    request: {
        url: `${URL_PROCESSES}processNames/${orchestratorId}`,
    },
});

export const fetchNotAssignedProcessesByOrchestrator = (orchestratorId, queueId) => ({
    type: FETCH_NOT_ASSIGNED_PROCESSES_BY_ORCHESTRATOR,
    request: {
        url: `${URL_PROCESSES}processNames/${orchestratorId}`,
        params: {
            onlyNotAssignedToQueue: true,
            queueId,
            currentUser: true,
        },
    },
});
export const updateProcessResources = (processId, resourcesId) => {
    const params = { resourcesId };
    return {
        type: UPDATE_ROBOT,
        request: {
            url: `${URL_PROCESSES}${processId}/resources`,
            method: "post",
            params,
        },
    };
};
export const updateProcessDataMapping = (
    processId,
    dataMappings,
    onSuccess,
    onError,
) => ({
    type: UPDATE_PROCESS_DATA_MAPPING,
    request: {
        url: `${URL_PROCESSES_DATA_MAPPING}/${processId}`,
        method: "PUT",
        data: JSON.stringify(dataMappings),
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});
export const fetchProcessDataMapping = (processId) => ({
    type: FETCH_PROCESS_DATA_MAPPING,
    request: {
        url: `${URL_PROCESSES_DATA_MAPPING}/${processId}`,
        method: "GET",
    },
});

export const fetchProcessInputs = (processId) => ({
    type: FETCH_PROCESS_INPUTS,
    request: {
        url: `${URL_PROCESSES}${processId}/inputs`,
        method: "GET",
    },
})
