import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Tooltip from "@material-ui/core/Tooltip"
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import get from "lodash/get";
import SettingsIcon from "@material-ui/icons/Settings";
import { isUIPathDisabled } from "util/constants/PermitedFeature";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrchestrator,
  createOrchestrator,
  modifyOrchestrator,
  fetchProcessesByOrchestrator,
  testOrchestratorConnection,
  fetchResourcesByOrchestrator,
  testOrchestratorUipathConnection,
  testOrchestratorIrisConnection,
} from "redux/actions/services/index";
import { toast } from "react-toastify";
import { ORCHESTRATORS_BASE_URL } from "util/helpers/route.helper";
import ConfigurationDrawer from "../components/ConfigurationDrawer";
import StatusDrawer from "../components/StatusDrawer";
import ConfirmMessage from "components/ConfirmMessage";
import ProcessesNameCard from "../components/ProcessesNameCard";
import CircularLoader from "components/Loaders/CircularLoader";
import InformationIcon from "components/InformationIcon";
import RobotsCard from "../components/RobotsCard";
import CustomButton from "components/CustomButton";
import DialogWithTabs from "components/FormComponents/DialogWithTabs";
import CustomSelectField from "components/FormFields/CustomSelectField";
import { CustomTextField } from "components/FormFields/CustomAutoComplete";
import { isPermitted } from "components/HasPermission";
import CustomSwitch from "pages/Services/components/CustomSwitch";
import useStyles from "./style.js";

export default function OrchestratorForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
    register,
    watch,
    formState: { isDirty },
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
      databaseServer: "",
      serverPort: "",
      databaseName: "",
      dbUsername: "",
      dbPassword: "",
      orchestratorType: "blueprism",
      serverType: "sqlserver",
      automaticTagsCreation: false,
      uipathOrchestratorDetail: {
        apiClientId: "",
        apiClientSecret: "",
        organizationName: "",
        tenantName: "",
        orchestratorApiName: "",
      },
        irisOrchestratorDetail: {
            irisUsername: "",
            irisPassword: "",
            irisUrl: "",
        }
    },
  });

  const history = useHistory();
  const { t } = useTranslation();
  const { idOrchestrator, mode = "add" } = useParams();

  const [isLoading, setIsLoading] = React.useState(false);
  const [processListLoading, setProcessListLoading] = React.useState(false);
  const [resourceListLoading, setResourceListLoading] = React.useState(false);
  const [configurationDrawer, setConfigurationDrawer] = React.useState(false);
  const [statusDrawer, setStatusDrawer] = React.useState(false);
  const [sessionNumber, setSessionNumber] = React.useState(0);
  const [dialogMsg, setDialogMsg] = React.useState("");
  const [orchestratorDataLoading, setOrchestratorDataLoading] = React.useState(false);
  const [selectedFleet, setSelectedFleet] = React.useState([]);
  const [orchestratorTypeUnderEdit, setOrchestratorTypeUnderEdit] = React.useState("");
  const orchestratorTypes = [
    { key: "Blue Prism", value: "blueprism" },
    ...!isUIPathDisabled ? [{ key: "Uipath", value: "uipath" }] : [],
      { key: "Iris", value: "iris" }
  ];
    const serverTypes = [
        { key: "SQL Server", value: "sqlserver" },
        { key: "MySQL", value: "mysql" },
    ];
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  const isUiPathOrchestrator = () => (watch("orchestratorType") === "uipath");

  const isIrisOrchestrator = watch("orchestratorType") === "iris";
    const [processesByOrchestrator, setProcessesByOrchestrator] = React.useState(
    [],
  );

  const [resourcesByOrchestrator, setResourcesByOrchestrator] = React.useState([]);
  useEffect(() => {
    if (idOrchestrator)
    {
        setResourceListLoading(true);
        dispatch(fetchResourcesByOrchestrator(idOrchestrator)).then((res) => {
          setResourceListLoading(false);
          setResourcesByOrchestrator(res?.data ?? []);
        });
    }
  }, [idOrchestrator]);

  useEffect(() => {
    if (mode !== "add") {
      setProcessListLoading(true);
      setOrchestratorDataLoading(true);
      dispatch(fetchOrchestrator(idOrchestrator)).then((res) => {
        setOrchestratorTypeUnderEdit(res.data.orchestratorType);
        setValue("name", res.data.name);
        setValue("description", res.data.description);
        setValue("databaseServer", res.data.databaseServer);
        setValue("serverPort", res.data.serverPort);
        setValue("databaseName", res.data.databaseName);
        setValue("dbUsername", res.data.dbUsername);
        setValue("dbPassword", res.data.dbPassword);
        setValue("orchestratorType", res.data.orchestratorType);
        setValue("serverType", res.data.serverType);
        setValue("uipathOrchestratorDetail", res.data.uipathOrchestratorDetail);
        setValue("automaticTagsCreation", res.data?.automaticTagsCreation);
        setValue("irisOrchestratorDetail", res.data.irisOrchestratorDetail);
        setSelectedFleet(res?.data?.fleets)
        setSessionNumber(res.data.sessionNumber);
        setOrchestratorDataLoading(false);
        dispatch(fetchProcessesByOrchestrator(res.data.id)).then((res) => {
          setProcessesByOrchestrator(res?.data ?? []);
          setProcessListLoading(false);
        });
      });
    }
  }, [idOrchestrator, dispatch, setValue, mode]);

  const [openMsgCancel, setOpenMsgCancel] = React.useState(false);
  const isViewMode = mode === "view"
  const handleCancel = () => {
    if (isViewMode || !isDirty) {
      redirectToList();
      return;
    }
    setOpenMsgCancel(true);
  };
  const handleNotification = (message, status) => {
    const msg = t(message);
    setDialogMsg(msg);
    if (status) {
      toast.success(msg);
    } else {
      toast.error(msg);
    }
  }
  const redirectToList = () => history.push(ORCHESTRATORS_BASE_URL);

  const handleSaveClick = async () => {
    const result = await trigger();
    if (result) {
      setOpenMsgConfirm(true);
    }
  };

  const handleRejectCancelForm = () => setOpenMsgCancel(false);
  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    setIsLoading(false);
    redirectToList()
  }

  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
    setIsLoading(false);
  };

  const confirmSave = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    if (mode === "add") {
      dispatch(
        createOrchestrator({ ...data, fleetIds: selectedFleet?.map(({ id }) => id) }, null, (res) => {
          // file is null but implementation is taking into account adding an image
          if (res?.status === 200) {
            setTimeout(() => {
              redirectToList();
              toast.success(t("orchestrator.formControl.saveSuccess"))
              setIsLoading(false);
            }, 400);
          }
        }),
      )
        .then((res) => {
          if (res.response.status !== "OK") {
            setOpenMsgConfirm(false);
            setIsLoading(false);
            handleNotification(res.response?.data?.message, false);
          }
        })
        .catch(() => {});
    } else {
      dispatch(
        modifyOrchestrator(idOrchestrator, { ...data, fleetIds: selectedFleet?.map(({ id }) => id) }, null, (res) => {
          if (res?.status === 200) {
            setTimeout(() => {
              redirectToList();
              toast.success(t("orchestrator.formControl.updateSuccess"))
              setIsLoading(false);
            }, 400);
          }
        }),
      )
        .then((res) => {
          if (res.response.status !== "OK") {
            setOpenMsgConfirm(false);
            setIsLoading(false);
            handleNotification("Unexpected Error", false);
          }
        })
        .catch(() => {});
    }
  };

  const testConnection = (data) => {
    const onSuccess = (res) => {
      if (res.data === true) {
        handleNotification("orchestrator.management.testConnection.successMessage", true);
      } else {
        handleNotification("orchestrator.management.testConnection.failureMessage", false);
      }
    };
    const onError = () => {
      handleNotification("orchestrator.management.testConnection.failureMessage", false);
    };
    if (watch("orchestratorType") === "uipath") {
      dispatch(testOrchestratorUipathConnection(data?.uipathOrchestratorDetail, onSuccess, onError));
    } else if (watch("orchestratorType") === "iris") {
        dispatch(testOrchestratorIrisConnection(data?.irisOrchestratorDetail, onSuccess, onError));
    }
    else
    {
      let connectionCredentials = data;
      if (mode !== "add") {
        connectionCredentials = { orchestratorId: idOrchestrator, ...connectionCredentials }
      }
      dispatch(testOrchestratorConnection(connectionCredentials, onSuccess, onError));
    }
  };

  const disableFields = !(mode === "add" || mode === "edit");
  const uipathIsSelected = watch("orchestratorType") === "uipath";
  const tabs = [
    { label: "fleet.formSection.fleetInfo" },
    { label: `${t("Process.list.names.by.orchestrator")}${uipathIsSelected ? "" : ` & ${t("Resource.list.names.by.orchestrator")}`}`, hidden: !isViewMode },
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  const handleChange = (_, newValue) => {
    setActiveStep(newValue);
  };

  const getServerTypeIndex = () => {
    const orchType = watch("orchestratorType")
    return +(orchType !== "blueprism");
  }

  const setDbVendor = () => {
    const index = getServerTypeIndex();
    return [{ value: serverTypes[index].value, key: serverTypes[index].key }]
  }

  useEffect(() => {
    const index = getServerTypeIndex();
    setValue("serverType", serverTypes[index].value);
    if (watch("orchestratorType") === orchestratorTypeUnderEdit) {
      dispatch(fetchOrchestrator(idOrchestrator), (res) => {
        setValue("name", res.data?.name);
        setValue("description", res.data?.description);
        setValue("databaseServer", res.data?.databaseServer);
        setValue("serverPort", res.data?.serverPort);
        setValue("databaseName", res.data?.databaseName);
        setValue("dbUsername", res.data?.dbUsername);
        setValue("dbPassword", res.data?.dbPassword);
        setValue("orchestratorType", res.data?.orchestratorType);
        setValue("serverType", res.data?.serverType);
        setValue("uipathOrchestratorDetail", res.data?.uipathOrchestratorDetail);
        setValue("automaticTagsCreation", res.data?.automaticTagsCreation);
        setSelectedFleet(res.data?.fleets)
        setSessionNumber(res.data?.sessionNumber);
      });
    }
  }, [watch("orchestratorType")]);

  const UiPathFields = () => (
    <>
      <Box>
        <CustomTextField
            {...register("uipathOrchestratorDetail.apiClientId", {
              required: {
                value: true,
                message: "orchestrator.management.formControl.required",
              },
            })}
            fullWidth
            disabled={disableFields}
            valued={getValues("uipathOrchestratorDetail.apiClientId")}
            id="rpa-orchestrator-apiClientId-input-field"
            InputProps={{
              endAdornment: (<InformationIcon
                  titleContent={t(
                      "orchestrator.management.formControl.apiClientId.information",
                  )}
              />),
            }}
            InputLabelProps={{
              shrink: !!watch("uipathOrchestratorDetail.apiClientId"),
            }}
            label={`${t("orchestrator.management.formControl.apiClientId")} *`}
            error={!!errors.uipathOrchestratorDetail?.apiClientId?.message}
            helperText={!!errors.uipathOrchestratorDetail?.apiClientId?.message && t(errors.uipathOrchestratorDetail?.apiClientId?.message)}
          />
      </Box>
      <Box>
        <CustomTextField
            {...register("uipathOrchestratorDetail.apiClientSecret", {
              required: {
                value: true,
                message: "orchestrator.management.formControl.required",
              },
            })}
            fullWidth
            disabled={disableFields}
            valued={getValues("uipathOrchestratorDetail.apiClientSecret")}
            id="rpa-orchestrator-apiClientSecret-input-field"
            InputProps={{
              endAdornment: (<InformationIcon
                  titleContent={t(
                      "orchestrator.management.formControl.apiClientSecret.information",
                  )}
              />),
            }}
            InputLabelProps={{
              shrink: !!watch("uipathOrchestratorDetail.apiClientSecret"),
            }}
            label={`${t(
              "orchestrator.management.formControl.apiClientSecret"
            )} *`}
            error={!!errors.uipathOrchestratorDetail?.apiClientSecret?.message}
            helperText={!!errors.uipathOrchestratorDetail?.apiClientSecret?.message && t(errors.uipathOrchestratorDetail?.apiClientSecret?.message)}
          />
      </Box>
      <Box>
        <CustomTextField
            {...register("uipathOrchestratorDetail.organizationName", {
              required: {
                value: true,
                message: "orchestrator.management.formControl.required",
              },
            })}
            fullWidth
            disabled={disableFields}
            valued={getValues("uipathOrchestratorDetail.organizationName")}
            id="rpa-orchestrator-organizationName-input-field"
            InputProps={{
              endAdornment: (<InformationIcon
                  titleContent={t(
                      "orchestrator.management.formControl.organizationName.information",
                  )}
              />),
            }}
            InputLabelProps={{
              shrink: !!watch("uipathOrchestratorDetail.organizationName"),
            }}
            label={`${t(
              "orchestrator.management.formControl.organizationName"
            )} *`}
            error={!!errors.uipathOrchestratorDetail?.organizationName?.message}
            helperText={!!errors.uipathOrchestratorDetail?.organizationName?.message && t(errors.uipathOrchestratorDetail?.organizationName?.message)}
          />
      </Box>
      <Box>
        <CustomTextField
            {...register("uipathOrchestratorDetail.tenantName", {
              required: {
                value: true,
                message: "orchestrator.management.formControl.required",
              },
            })}
            fullWidth
            disabled={disableFields}
            valued={getValues("uipathOrchestratorDetail.tenantName")}
            id="rpa-orchestrator-tenantName-input-field"
            InputProps={{
              endAdornment: (<InformationIcon
                  titleContent={t(
                      "orchestrator.management.formControl.tenantName.information",
                  )}
              />),
            }}
            InputLabelProps={{
              shrink: !!watch("uipathOrchestratorDetail.tenantName"),
            }}
            label={`${t("orchestrator.management.formControl.tenantName")} *`}
            error={!!errors.uipathOrchestratorDetail?.tenantName?.message}
            helperText={!!errors.uipathOrchestratorDetail?.tenantName?.message && t(errors.uipathOrchestratorDetail?.tenantName?.message)}
          />
      </Box>
      <Box>
        <CustomTextField
            {...register("uipathOrchestratorDetail.orchestratorApiName", {
              required: {
                value: true,
                message: "orchestrator.management.formControl.required",
              },
            })}
            fullWidth
            disabled={disableFields}
            valued={getValues("uipathOrchestratorDetail.orchestratorApiName")}
            id="rpa-orchestrator-orchestratorApiName-input-field"
            InputProps={{
              endAdornment: (<InformationIcon
                  titleContent={t(
                      "orchestrator.management.formControl.orchestratorApiName.information",
                  )}
              />),
            }}
            InputLabelProps={{
              shrink: !!watch("uipathOrchestratorDetail.orchestratorApiName"),
            }}
            label={`${t(
              "orchestrator.management.formControl.orchestratorApiName"
            )} *`}
            error={!!errors.uipathOrchestratorDetail?.orchestratorApiName?.message}
            helperText={!!errors.uipathOrchestratorDetail?.orchestratorApiName?.message && t(errors.uipathOrchestratorDetail?.orchestratorApiName?.message)}
          />
      </Box>
    </>
  );
    const IrisFields = () => (
      <>
        <Box>
          <CustomTextField
                      {...register("irisOrchestratorDetail.irisUsername", {
                            required: {
                                value: true,
                                message: "orchestrator.management.formControl.required",
                            },
                      })}
                      fullWidth
                      disabled={disableFields}
                      valued={getValues("irisOrchestratorDetail.irisUsername")}
                      id="rpa-orchestrator-irisUsername-input-field"
                      InputLabelProps={{
                          shrink: !!watch("irisOrchestratorDetail.irisUsername"),
                      }}
                      label={`${t("orchestrator.management.formControl.irisUsername")} *`}
                      error={!!errors.irisOrchestratorDetail?.irisUsername?.message}
                      helperText={!!errors.irisOrchestratorDetail?.irisUsername?.message && t(errors.irisOrchestratorDetail?.irisUsername?.message)}
                  />
        </Box>
        <Box>
          <CustomTextField
                      {...register("irisOrchestratorDetail.irisPassword", {
                            required: {
                                value: true,
                                message: "orchestrator.management.formControl.required",
                            },
                      })}
                      fullWidth
                      disabled={disableFields}
                      valued={getValues("irisOrchestratorDetail.irisPassword")}
                      id="rpa-orchestrator-irisPassword-input-field"
                      type="password"
                      InputLabelProps={{
                          shrink: !!watch("irisOrchestratorDetail.irisPassword"),
                      }}
                      label={`${t(
                          "orchestrator.management.formControl.irisPassword"
                      )} *`}
                      error={!!errors.irisOrchestratorDetail?.irisPassword?.message}
                      helperText={!errors.irisOrchestratorDetail?.irisPassword?.message && t(errors.irisOrchestratorDetail?.irisPassword?.message)}
                  />
        </Box>

        <Box>
          <CustomTextField
                      {...register("irisOrchestratorDetail.irisUrl", {
                            required: {
                                value: true,
                                message: "orchestrator.management.formControl.required",
                            },
                      })}
                      fullWidth
                      disabled={disableFields}
                      valued={getValues("irisOrchestratorDetail.irisUrl")}
                      id="rpa-orchestrator-irisUrl-input-field"
                      InputLabelProps={{
                          shrink: !!watch("irisOrchestratorDetail.irisUrl"),
                      }}
                      label={`${t(
                          "orchestrator.management.formControl.irisUrl"
                      )} *`}
                      error={!!errors.irisOrchestratorDetail?.irisUrl?.message}
                      helperText={!!errors.irisOrchestratorDetail?.irisUrl?.message && t(errors.irisOrchestratorDetail?.irisUrl?.message)}
                  />
        </Box>
      </>
    );

  const tabsContents = [
    {
      content: orchestratorDataLoading ? (
        <CircularLoader height="100%" />
      ) : (
        <Box component={Grid} display="grid" gridTemplateColumns="1FR 1FR" gridColumnGap="70px" gridRowGap="25px">
          <Box>
            <CustomSelectField
                  {...register("orchestratorType", {
                    required: {
                      value: true,
                      message: t("orchestrator.management.formControl.required"),
                    },
                  })}
                  value={getValues("orchestratorType")}
                  options={orchestratorTypes ?? []}
                  optionLabel="key"
                  optionValue="value"
                  valued={getValues("orchestratorType")}
                  onChange={(e) => {
                    setValue("orchestratorType", e.target.value);
                  }}
                  variant="standard"
                  label={t("orchestrator.management.formControl.orchestratorType")}
                  disabled={disableFields}
                  error={!!errors.orchestratorType}
                  helperText={!!errors.orchestratorType && errors?.orchestratorType?.message}
                  isCustom
              />
          </Box>
          <Box>
            <CustomTextField
                  {...register("name", {
                    required: {
                      value: true,
                      message: "orchestrator.management.formControl.required",
                    },
                  })}
                  fullWidth
                  id="rpa-orchestrator-orchestratorName-input-field"
                  name="name"
                  label={`${t(
                      "orchestrator.management.formControl.orchestratorName",
                  )} *`}
                  disabled={disableFields}
                  valued={getValues("name")}
                  InputProps={{
                    endAdornment: (<InformationIcon
                        titleContent={t(
                            "orchestrator.management.formControl.orchestratorName.information",
                        )}
                    />),
                  }}
                  InputLabelProps={{
                    shrink: !!watch("name"),
                  }}
                  error={!!errors.name?.message}
                  helperText={!!errors.name?.message && t(errors.name?.message)}
              />
          </Box>
          <Box>
            <CustomTextField
                  {...register("description")}
                  fullWidth
                  multiline
                  disabled={disableFields}
                  valued={getValues("description")}
                  id="rpa_orchestrator_description-input-field"
                  InputProps={{
                    endAdornment: (<InformationIcon
                        titleContent={t(
                            "orchestrator.management.formControl.description.information",
                        )}
                    />),
                  }}
                  InputLabelProps={{
                    shrink: !!watch("description"),
                  }}
                  label={t("orchestrator.management.formControl.description")}
              />
          </Box>
          {!isUiPathOrchestrator() && (
            <>
              <Box>
                <CustomTextField
                    {...register("serverPort", {
                      required: {
                        value: true,
                        message: "orchestrator.management.formControl.required",
                      },
                      validate: (data) => {
                        const input = parseInt(data);
                        if (
                            !Number.isInteger(input)
                            || input < 0
                            || input >= 65535
                        ) {
                          return "orchestrator.management.formControl.invalidPort";
                        }
                        return true;
                      },
                    })}
                    fullWidth
                    disabled={disableFields}
                    valued={!!watch("serverPort") || watch("serverPort") === 0}
                    id="rpa-orchestrator-serverPort-input-field"
                    InputProps={{
                      endAdornment: (<InformationIcon
                          titleContent={t(
                              "orchestrator.management.formControl.serverPort.information",
                          )}
                      />),
                    }}
                    InputLabelProps={{
                      shrink: !!watch("serverPort") || watch("serverPort") === 0,
                    }}
                    label={`${t(
                        "orchestrator.management.formControl.serverPort",
                    )} *`}
                    error={!!errors.serverPort?.message}
                    helperText={!!errors.serverPort?.message && t(errors.serverPort?.message)}
                />
              </Box>
              <Box>
                <CustomTextField
                        {...register("databaseName", {
                          required: {
                            value: true,
                            message: "orchestrator.management.formControl.required",
                          },
                        })}
                        fullWidth
                        disabled={disableFields}
                        valued={getValues("databaseName")}
                        id="rpa-orchestrator-databaseName-input-field"
                        InputProps={{
                          endAdornment: (<InformationIcon
                              titleContent={t(
                                  "orchestrator.management.formControl.databaseName.information",
                              )}
                          />),
                        }}
                        InputLabelProps={{
                          shrink: !!watch("databaseName"),
                        }}
                        label={`${t(
                            "orchestrator.management.formControl.databaseName",
                        )} *`}
                        error={!!errors.databaseName?.message}
                        helperText={!!errors.databaseName?.message && t(errors.databaseName?.message)}
                    />
              </Box>

              <Box>
                <CustomTextField
                        {...register("databaseServer", {
                          required: {
                            value: true,
                            message: "orchestrator.management.formControl.required",
                          },
                        })}
                        fullWidth
                        disabled={disableFields}
                        valued={getValues("databaseServer")}
                        id="rpa-orchestrator-databaseServer-input-field"
                        InputProps={{
                          endAdornment: (<InformationIcon
                              titleContent={t(
                                  "orchestrator.management.formControl.databaseServer.information",
                              )}
                          />),
                        }}
                        InputLabelProps={{
                          shrink: !!watch("databaseServer"),
                        }}
                        label={`${t(
                            "orchestrator.management.formControl.databaseServer",
                        )} *`}
                        error={!!errors.databaseServer?.message}
                        helperText={!!errors.databaseServer?.message && t(errors.databaseServer?.message)}
                    />
              </Box>
              <Box>
                <CustomTextField
                        {...register("dbUsername", {
                          required: {
                            value: true,
                            message: "orchestrator.management.formControl.required",
                          },
                        })}
                        fullWidth
                        disabled={disableFields}
                        valued={getValues("dbUsername")}
                        id="rpa-orchestrator-dbUsername-input-field"
                        InputProps={{
                          endAdornment: (<InformationIcon
                              titleContent={t(
                                  "orchestrator.management.formControl.dbUsername.information",
                              )}
                          />),
                        }}
                        InputLabelProps={{
                          shrink: !!watch("dbUsername"),
                        }}
                        label={`${t(
                            "orchestrator.management.formControl.dbUsername",
                        )} *`}
                        error={!!errors.dbUsername?.message}
                        helperText={!!errors.dbUsername?.message && t(errors.dbUsername?.message)}
                    />
              </Box>
              <Box>
                <CustomTextField
                        {...register("dbPassword", {
                          required: {
                            value: true,
                            message: "orchestrator.management.formControl.required",
                          },
                        })}
                        type="password"
                        fullWidth
                        disabled={disableFields}
                        valued={getValues("dbPassword")}
                        id="rpa-orchestrator-dbPassword-input-field"
                        InputProps={{
                          endAdornment: (<InformationIcon
                              titleContent={t(
                                  "orchestrator.management.formControl.dbPassword.information",
                              )}
                          />),
                        }}
                        InputLabelProps={{
                          shrink: !!watch("dbPassword"),
                        }}
                        label={`${t(
                            "orchestrator.management.formControl.dbPassword",
                        )} *`}
                        error={!!errors.dbPassword?.message}
                        helperText={!!errors.dbPassword?.message && t(errors.dbPassword?.message)}
                    />
              </Box>
            </>
            )}
          <Box>
            <CustomSelectField
                  {...register("serverType", {
                    required: {
                      value: true,
                      message: "orchestrator.management.formControl.required",
                    },
                  })}
                  value={watch("serverType")}
                  options={setDbVendor() ?? []}
                  optionLabel="key"
                  optionValue="value"
                  valued={getValues("serverType")}
                  onChange={(e) => {
                    setValue("serverType", e.target.value);
                  }}
                  variant="standard"
                  label={`${t("server Type",)} *`}
                  disabled={disableFields}
                  error={!!errors.serverType}
                  helperText={!!errors.serverType && errors?.serverType?.message}
                  isCustom
              />
          </Box>
          {!isUiPathOrchestrator() && (
            <>
              <Box>
                <CustomTextField
                              fullWidth
                              disabled
                              value={sessionNumber}
                              valued={sessionNumber}
                              id="rpa-orchestrator-sessionNumber-input-field"
                              label={t(
                                  "orchestrator.management.formControl.sessionNumber",
                              )}
                              InputProps={{
                                endAdornment: (<InformationIcon
                                    titleContent={t(
                                        "orchestrator.management.formControl.sessionNumber.information",
                                    )}
                                />),
                              }}
                          />
              </Box>
              <Box display="flex">
                <InformationIcon
                  titleContent={t(
                      "orchestrator.management.formControl.automatic.tags.creation.information",
                  )}
                  originalContent={(
                    <CustomSwitch
                          label={t("orchestrator.management.formControl.automatic.tags.creation")}
                          checked={watch("automaticTagsCreation")}
                          handleChange={(e) => setValue("automaticTagsCreation", e.target.checked)}
                          disabled={disableFields}
                      />
                  )}
                />
              </Box>
            </>
                )}
          {uipathIsSelected && UiPathFields()}
          {isIrisOrchestrator && IrisFields()}
        </Box>
      ),
      actions: isViewMode ? null : (
        <>
          <CustomButton
                view="cancelModal"
                onClick={handleCancel}
            >
            {t("user.button.cancel")}
          </CustomButton>
          <CustomButton
                view="cancelModal"
                onClick={() => handleSubmit(testConnection)()}
                className={classes.testConnectionButton}
            >
            {t("orchestrator.management.testConnection")}
          </CustomButton>
          <CustomButton
                view="primary"
                size="medium"
                type="button"
                onClick={handleSaveClick}
            >
            {idOrchestrator
                  ? t("orchestrator.button.update")
                  : t("orchestrator.button.save")}
          </CustomButton>
        </>
      ),
      fixedActions: isViewMode ? (
        <Tooltip title={dialogMsg}>
          <CustomButton
                  view="primary"
                  onClick={() => handleSubmit(testConnection)()}
              >
            {t("orchestrator.management.testConnection")}
          </CustomButton>
        </Tooltip>
      ) : null,
    },
    {
      content: (
        <Box display="grid" gridTemplateColumns={`1FR ${uipathIsSelected ? "" : "1FR"}`} gridColumnGap="30px">
          <Box className={classes.processesContainer}>
            {!processListLoading ? (
              <ProcessesNameCard processes={processesByOrchestrator} />
              ) : (
                <CircularLoader />
              )}
          </Box>
          {!uipathIsSelected && (
            <Box className={classes.processesContainer}>
              {!resourceListLoading ? (
                <RobotsCard resources={resourcesByOrchestrator} />
                ) : (
                  <CircularLoader />
                )}
            </Box>
            )}
        </Box>
      ),
      hidden: !isViewMode,
    },
  ]

  const customIcons = [
    { icon: <CustomButton startIcon={<DoneIcon />} className={classes.statusButton} onClick={() => setStatusDrawer(true)}>{t("Status")}</CustomButton>, title: t("Status") },
    { icon: <IconButton onClick={() => setConfigurationDrawer(true)}><SettingsIcon color="primary" /></IconButton>, title: t("configuration") },
  ]

  return (
    <>
      <DialogWithTabs
          open
          entityBaseUrl={ORCHESTRATORS_BASE_URL}
          moduleName="orchestrator"
          tabs={tabs}
          disableFields={disableFields}
          tabsContents={tabsContents}
          idItem={idOrchestrator}
          handleChange={handleChange}
          editCondition={activeStep === 0 && isPermitted(currentUser, "Edit Orchestrator")}
          activeStep={activeStep}
          styledContent={!(activeStep === 1)}
          customIcons={customIcons}
      />
      {openMsgConfirm && (
      <ConfirmMessage
            message={
              idOrchestrator
                ? t("orchestrator.update.confirmMsg")
                : t("orchestrator.save.confirmMsg")
            }
            openStart={openMsgConfirm}
            onCancel={cancelConfirm}
            onConfirm={confirmSave}
            buttonConfirm={
              idOrchestrator
                ? t("orchestrator.button.update")
                : t("orchestrator.button.save")
            }
            buttonCancel={t("Cancel")}
            isLoading={isLoading}
          />
        )}

      {openMsgCancel && (
      <ConfirmMessage
            message={t("orchestrator.delete.discard")}
            openStart={openMsgCancel}
            onCancel={handleRejectCancelForm}
            onConfirm={handleAcceptCancelForm}
            buttonConfirm={t("Discard")}
            buttonCancel={t("Cancel")}
            isLoading={false}
          />
        )}
      <ConfigurationDrawer
        open={configurationDrawer}
        setOpen={setConfigurationDrawer}
        orchestratorId={idOrchestrator}
        orchestratorType={watch("orchestratorType")}
      />
      <StatusDrawer
        open={statusDrawer}
        setOpen={setStatusDrawer}
        orchestratorId={idOrchestrator}
        orchestratorType={watch("orchestratorType")}
      />
    </>
  );
}
