import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@redux-requests/react";
import { toast } from "react-toastify";
import { isFleetAdministrator } from "util";

import {
  addTag,
  updateTag,
  fetchTagById,
  fetchFleetsForFilter, fetchProcessesForAdministration,
} from "../../../../redux/actions/services/index";
import ConfirmMessage from "components/ConfirmMessage/index.js";
import ProcessSelector from "../../ProcessSelector/index.js";
import {
  FETCH_ALL_FLEETS_FOR_FILTER, FETCH_PROCESSES_FOR_ADMINISTRATION,
} from "../../../../redux/constants/index";
import {
  isPermitted,
} from "../../../../components/HasPermission";
import get from "lodash/get";
import { TAGS_BASE_URL } from "util/helpers/route.helper";
import CustomButton from "../../../../components/CustomButton";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import FormControl from "@material-ui/core/FormControl";
import CustomAutoComplete, { CustomTextField } from "../../../../components/FormFields/CustomAutoComplete";
import DialogWithTabs from "../../../../components/FormComponents/DialogWithTabs";

const tabs = [
  {
    id: "tag-overview-tab",
    label: "fleet.formSection.fleetInfo",
  },
  {
    id: "tag-process-tab",
    label: "user.add.process",
  },
];

export default function TagForm(props) {
  const { open } = props;
  const { idTag, mode = "add" } = useParams();
  const disableFields = mode === "view";
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [availableProcesses, setAvailableProcesses] = useState([]);
  const [assignedProcesses, setAssignedProcesses] = useState([]);
  const [openMsgCancel, setOpenMsgCancel] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [tagDataLoading, setTagDataLoading] = useState(false);
  const [tag, setTag] = useState(null);
  const [selectedFleets, setSelectedFleets] = useState(null);
  const history = useHistory();
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const [isInstanceOwner, setIsInstanceOwner] = useState(false);

  React.useEffect(() => {
    setIsInstanceOwner(currentUser?.fleet?.instanceOwner && isFleetAdministrator(currentUser));
  }, [currentUser]);
  const handleRejectCancelForm = () => {
    setOpenMsgCancel(false);
  };

  const {
    formState: { errors },
    handleSubmit,
    trigger,
    register,
    setValue,
    getValues,
    formState: { isDirty },
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      id: 0,
      name: "",
      description: "",
      status: "ACTIVE",
    },
  });

  const processes = useQuery({ type: FETCH_PROCESSES_FOR_ADMINISTRATION }).data;
  const fleets = useQuery({ type: FETCH_ALL_FLEETS_FOR_FILTER }).data;
  const handleCancel = () => {
    if (mode === "view" || !isDirty) {
      history.push(TAGS_BASE_URL);
      return;
    }
    setOpenMsgCancel(true);
  };

  useEffect(() => {
    if (mode !== "add") {
      setTagDataLoading(true);
      dispatch(fetchTagById(idTag)).then((res) => {
        if (res.status === 200) {
          setValue("name", res.data.name);
          setValue("description", res.data.description);
          setValue("fleets", res?.data?.fleets);
          setSelectedFleets(res?.data?.fleets?.map((fleet) => fleet?.id));
          setTag(res.data);
          setTagDataLoading(false);
        }
      });
    }
  }, [idTag, mode]);

  useEffect(() => {
    const transformedProcesses = processes?.map((proc) => ({
      id: proc.id,
      icon: proc.icon,
      processDescription: { processDisplayName: proc.processName }
    }))
    const transformedAssignedProcesses = tag?.processDtos?.map((proc) => ({
      id: proc.id,
      icon: proc.icon,
      processDescription: { processDisplayName: proc.name }
    }))
    if (mode === "add") {
      setAvailableProcesses(transformedProcesses);
      return;
    }
    if (Array.isArray(transformedProcesses) && tag) {
      const _availableProcesses = transformedProcesses.filter(
        (p) => !tag.processDtos.map(({ id }) => id).find((pq) => pq === p.id)
      );
      setAvailableProcesses(_availableProcesses);
      setAssignedProcesses(
          transformedAssignedProcesses
      );
    }
  }, [processes, tag]);

  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    history.push(TAGS_BASE_URL);
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };

  const handleChange = async (event, newValue) => {
    const result = await trigger();
    if (result) {
      setActiveStep(newValue);
    }
  };

  const handleButtonClick = async () => {
    const result = await trigger();
    if (result && isFleetsFilled) {
      setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? 0 : prevActiveStep - 1));
      setActiveStep(1);
    }
  };

  const handleBack = () => {
    if (activeStep <= 0) handleCancel();
    setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? 0 : prevActiveStep - 1));
    setActiveStep(0)
  };

  const handleSaveClick = async () => {
    const result = await trigger();
    if (result && isFleetsFilled) {
      if (activeStep === tabs.length - 1) {
        setOpenMsgConfirm(true);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const confirmSave = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    const tag = {
      name: data.name,
      description: data.description,
      processesIds: assignedProcesses?.map((p) => p.id),
      fleets: selectedFleets?.map((id) => ({ id }))
    }
    formData.append("tag", JSON.stringify(tag))
    dispatchSave(formData);
  };
  const dispatchSave = (formData) => {
    const saveAction = mode === "add" ? addTag(formData) : updateTag(idTag, formData);
    const successMessage = mode === "add"
        ? t("tag.management.form.save.success")
        : t("tag.management.form.update.success");
    const errorMessage = mode === "add"
        ? t("tag.management.form.save.error")
        : t("tag.management.form.update.error");
    dispatch(saveAction).then((res) => {
      if (res.status === 201 || res.status === 200) {
        setOpenMsgConfirm(false);
        history.push({
          pathname: TAGS_BASE_URL,
        });
        toast.success(successMessage);
      } else if (res.error.response.status === 409) {
        setOpenMsgConfirm(false);
        toast.error(t(res.error.response.data.title));
      } else {
        setOpenMsgConfirm(false);
        toast.error(errorMessage);
      }
    });
  };

  useEffect(() => {
    if (isInstanceOwner) {
      dispatch(fetchFleetsForFilter());
    }
  }, [isInstanceOwner, dispatch]);
  useEffect(() => {
    if (!selectedFleets && currentUser?.fleet?.id) setSelectedFleets([currentUser.fleet.id]);
  }, [currentUser, fleets]);

  useEffect(() => {
    let sourceOfFleet = selectedFleets;
    if (!selectedFleets) {
      sourceOfFleet = [currentUser?.fleet?.id];
    }
    dispatch(fetchProcessesForAdministration({ tagsIds: [], fleetIds: [sourceOfFleet] }));
  }, [currentUser, dispatch, selectedFleets]);
  const handleChangeFleet = (values) => {
    if (values) {
      setSelectedFleets(values?.map((fleet) => fleet?.id))
    }
  }

  const isFleetsFilled = selectedFleets?.length > 0

  const tabsContents = [
    {
      content: (
          tagDataLoading ? (
            <CircularLoader height="100%" />
          ) : (
            <Grid
              container
              direction="column"
              justify="space-between"
              spacing={5}
            >
              {isInstanceOwner && (
                <Grid item>
                  <FormControl fullWidth>
                    <CustomAutoComplete
                      multiple
                      options={fleets ?? []}
                      optionLabel="companyName"
                      value={fleets?.filter((item) => selectedFleets?.includes(item?.id))}
                      onChange={handleChangeFleet}
                      label={`${t("groups")}`}
                      error={!isFleetsFilled}
                      helperText={!isFleetsFilled && t("user.management.formControl.required")}
                      disabled={disableFields}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item>
                <CustomTextField
                  {...register("name", {
                    required: {
                      value: true,
                      message: t("user.management.formControl.required"),
                    },
                  })}
                  id="name"
                  label={`${t("tag.form.name")} *`}
                  InputLabelProps={{
                    shrink: !!getValues("name"),
                  }}
                  onChange={(event) => {
                    clearErrors("name");
                    setValue("name", event.target.value);
                  }}
                  fullWidth
                  error={!!errors.name?.message}
                  helperText={errors.name?.message}
                  valued={getValues("name")}
                  disabled={disableFields}
                  />
              </Grid>
              <Grid item>
                <CustomTextField
                    {...register("description", {
                      required: {
                        value: true,
                        message: t("user.management.formControl.required"),
                      },
                    })}
                    id="tagDescription"
                    label={`${t("tag.form.description")} *`}
                    InputLabelProps={{
                      shrink: !!getValues("description"),
                    }}
                    onChange={(event) => {
                      clearErrors("description");
                      setValue("description", event.target.value);
                    }}
                    fullWidth
                    error={!!errors.description?.message}
                    helperText={errors.description?.message}
                    valued={getValues("description")}
                    disabled={disableFields}
                  />
              </Grid>
            </Grid>
            )
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={() => handleCancel()}>
            {t("user.button.cancel")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleButtonClick}>
            {t("tag.form.next")}
          </CustomButton>
        </>
      ),
    },
    {
      content: (
        <Grid item xs={12}>
          <ProcessSelector
            availableProcesses={availableProcesses}
            selectedProcesses={assignedProcesses}
            setAvailableProcesses={setAvailableProcesses}
            setSelectedProcesses={setAssignedProcesses}
            isDisabled={disableFields}
          />
        </Grid>
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("tag.add.previous")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            {idTag ? t("tag.management.update") : t("tag.management.add")}
          </CustomButton>
        </>
      ),
    },
  ];

  return (
    <>
      <DialogWithTabs
          open={open}
          entityBaseUrl={TAGS_BASE_URL}
          moduleName="tags"
          showEditModal={props?.showEditModal}
          tabs={tabs}
          disableFields={disableFields}
          editCondition={isPermitted(currentUser, "Edit tag")}
          tabsContents={tabsContents}
          idItem={idTag}
          handleChange={handleChange}
          activeStep={activeStep}
        />
      {openMsgConfirm && (
        <ConfirmMessage
                message={
                  idTag
                      ? t("tag.management.update.confirm-msg")
                      : t("tag.management.save.confirm-msg")
                }
                openStart={openMsgConfirm}
                onCancel={cancelConfirm}
                onConfirm={confirmSave}
                buttonCancel={t("Cancel")}
                buttonConfirm={
                  idTag ? t("tag.management.update") : t("tag.management.add")
                }
                isLoading={false}
            />
        )}
      {openMsgCancel && (
        <ConfirmMessage
                message={t("user.delete.discard")}
                openStart={openMsgCancel}
                onCancel={handleRejectCancelForm}
                onConfirm={handleAcceptCancelForm}
                buttonCancel={t("Cancel")}
                buttonConfirm={t("fleet.button.discard")}
                isLoading={false}
            />
        )}
    </>
  );
}
